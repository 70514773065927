import { PRICE_CONFIG } from '@apps/card/config';
import React, { useCallback } from 'react';
import { useCardCategoryContext } from '../CardCategoryProvider';
import { createContext } from '@shared/utils/createContext';
import { Sale, useSalesConfig } from '@apps/card/routes/CardCustomizer/useSalesConfig';
import { useMinorUnitsToPriceString } from '@apps/card/hooks/useMinorUnitsToPriceString';
import { isPromoValidForVendor, PromotionVendor } from '@apps/card/routes/CardCustomizer/usePromotionConfig';

type CardDiscountContext = {
  discountEnabled: boolean;
  discountedPrice: string | undefined;
  sale: Sale | undefined;
};

type useCardDiscountContextProps = {
  children: string | JSX.Element | JSX.Element[];
};

const [Provider, useCardDiscountContext] = createContext<CardDiscountContext | undefined>({ name: 'CardDiscountProvider' });

const useGetDiscountedPrice = () => {
  const { currentCategory } = useCardCategoryContext();
  const cardPricingConfig = PRICE_CONFIG.quantity[currentCategory];
  const { currentSale, isEnabled } = useSalesConfig(currentCategory);
  const priceToString = useMinorUnitsToPriceString();
  return useCallback(
    (vendor?: PromotionVendor) => {
      let newPriceMultiplier = 1.0;

      if (isEnabled && currentSale && (vendor ? isPromoValidForVendor(currentSale, vendor) : true)) {
        newPriceMultiplier = 1.0 - currentSale.discountPercentage;
      } else {
        return { isEnabled: false };
      }

      const discountedPrice = Math.round((cardPricingConfig[100].totalPriceInMinorUnits / 100) * newPriceMultiplier);
      const formattedPrice = priceToString(discountedPrice, { canShowFreeLabel: false });
      return { discountedPrice: formattedPrice, isEnabled, currentSale, currentCategory };
    },
    [cardPricingConfig, currentCategory, currentSale, isEnabled, priceToString]
  );
};

export const CardDiscountProvider = ({ children }: useCardDiscountContextProps) => {
  const getDiscountedPrice = useGetDiscountedPrice();
  const { isEnabled, discountedPrice, currentSale } = getDiscountedPrice();
  return <Provider value={{ discountEnabled: isEnabled, discountedPrice, sale: currentSale }}>{children}</Provider>;
};

export { useCardDiscountContext, useGetDiscountedPrice };
