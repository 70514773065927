import { animationTransition } from '@shared/utils/animationTransition';
import { Box } from '@withjoy/joykit';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { VimeoContainer } from '../../CardTileConcierge';
import { PropContent } from '../../../../../usePropValues';
import { useVimeoPlayerContext } from '@apps/registry/common/components/Catalog/utils/vimeoPlayer';
import Player from '@vimeo/player';

type CarouselVideoContainerProps = {
  currentPosition: number;
  asset: PropContent;
  onClick?: () => void;
};

export const CarouselVideoContainer = (props: CarouselVideoContainerProps) => {
  const { asset, onClick, currentPosition } = props;

  const isShowing = currentPosition === 0;

  const { VimeoPlayer, prepareForUse } = useVimeoPlayerContext();
  const placeholderImageRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<HTMLIFrameElement>(null);
  const [videoPlayer, setVideoPlayer] = useState<Player | null>(null);

  const videoUrl = useMemo(() => {
    if (asset.type !== 'video') {
      return null;
    }

    const url = new URL(asset.videoUrl);
    const searchParams = url.searchParams;
    searchParams.append('muted', '1');
    searchParams.append('loop', '1');
    searchParams.append('background', '1');
    return url.href;
  }, [asset]);

  const showAndPlay = (player?: Player) => {
    const video = player ?? videoPlayer;
    if (isShowing && video?.getPaused()) {
      video.play().catch(() => {
        // Ignore the error because it's expected when the video is forcibly paused
      });
    }
  };

  const onPlayerLoad = () => {
    if (playerRef && playerRef.current && VimeoPlayer && !videoPlayer) {
      const player = new VimeoPlayer(playerRef.current);
      setVideoPlayer(player);
      showAndPlay(player);
    }
    placeholderImageRef.current?.style.setProperty('opacity', '0');
  };

  useEffect(() => {
    prepareForUse();
  }, [prepareForUse]);

  if (asset.type !== 'video') {
    return null;
  }

  const { videoThumbnailUrl } = asset;

  if (!isShowing) {
    placeholderImageRef.current?.style.setProperty('opacity', '1');
  }

  return (
    <Box onClick={onClick} zIndex={30} cursor={onClick !== undefined ? 'pointer' : undefined} width="100%" height="100%" position="relative">
      <Box
        style={{
          transition: animationTransition('transform'),
          transform: `translateX(${100 * currentPosition}%)`
        }}
      >
        <Box
          position="absolute"
          width="100%"
          height="100%"
          paddingTop="177.78%"
          backgroundColor="mono1"
          backgroundPosition="center"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundImage={`url('${videoThumbnailUrl}')`}
        >
          {isShowing && <VimeoContainer onLoad={onPlayerLoad} r={playerRef} src={videoUrl!} />}
        </Box>
      </Box>
    </Box>
  );
};
