import { Flex } from '@withjoy/joykit';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { PropContent } from '../../../../../usePropValues';
import { CarouselImageContainer } from './CarouselImageContainer';
import { SalePurpleColor } from '../../../../CardTileDiscountedPriceLabel';
import { CarouselVideoContainer } from './CarouselVideoContainer';
import { CarouselButton } from './CarouselButton.styles';
import { withWindow } from '@shared/utils/withWindow';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';

type ContentCarouselProps = {
  content: PropContent[];
  link?: string;
  label?: string;
  autoRotatePeriodMs?: number;
  currentResponsiveSize: PropContent['responsiveTarget'];
};

const ContentCarousel = (props: ContentCarouselProps) => {
  const { content, currentResponsiveSize, link, label, autoRotatePeriodMs } = props;
  const mobile = useIsMobileScreen();
  const pagesLength = content.filter(asset => asset.responsiveTarget === currentResponsiveSize).length;
  const [currentPage, setCurrentPage] = useState(0);
  const [hover, setHover] = useState(false);
  const autoRotateTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const clickHandler =
    (link && !label) || (mobile && link)
      ? () => {
          withWindow(global => {
            global.location.href = link;
          });
        }
      : undefined;

  if (currentPage >= pagesLength) {
    setCurrentPage(pagesLength - 1);
  }

  const nextPage = useCallback(() => {
    setCurrentPage(page => {
      if (page < pagesLength - 1) {
        return ++page;
      } else {
        return 0;
      }
    });
  }, [pagesLength]);

  useEffect(() => {
    if (autoRotatePeriodMs && pagesLength > 1) {
      if (!autoRotateTimerRef.current)
        autoRotateTimerRef.current = setInterval(() => {
          nextPage();
        }, autoRotatePeriodMs);
    }

    return () => {
      autoRotateTimerRef.current && clearTimeout(autoRotateTimerRef.current);
    };
  }, [autoRotatePeriodMs, nextPage, pagesLength]);

  let slideIndex = -1;
  let navIndex = -1;
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }} onMouseOver={() => setHover(true)} onFocus={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {content.map(asset => {
        if (asset.responsiveTarget !== currentResponsiveSize) {
          return null;
        }

        slideIndex++;
        switch (asset.type) {
          case 'image':
            return <CarouselImageContainer key={slideIndex} asset={asset} currentPosition={slideIndex - currentPage} onClick={clickHandler} />;
            break;
          case 'video':
            return <CarouselVideoContainer key={slideIndex} asset={asset} currentPosition={slideIndex - currentPage} onClick={clickHandler} />;
            break;
        }
      })}

      <CarouselNavContainer link={link} label={label} showButton={hover}>
        {content.map(asset => {
          if (asset.responsiveTarget !== currentResponsiveSize) {
            return null;
          }

          navIndex++;
          return (
            <CarouselDot
              key={navIndex}
              index={navIndex}
              active={navIndex === currentPage}
              onSelect={page => {
                setCurrentPage(page);
                autoRotateTimerRef.current && clearInterval(autoRotateTimerRef.current);
              }}
            />
          );
        })}
      </CarouselNavContainer>
    </div>
  );
};

const CarouselNavContainer = ({ children, link, label, showButton: shouldShowButton }: { children?: ReactNode[]; link?: string; label?: string; showButton?: boolean }) => {
  const dotCount = children?.filter(child => child !== null).length ?? 0;
  const [showButton, setShowButton] = useState(shouldShowButton);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (shouldShowButton) {
      setShowButton(true);
    }

    if (!shouldShowButton && showButton) {
      timeoutRef.current = setTimeout(() => {
        setShowButton(false);
      }, 400);
    }

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [shouldShowButton, showButton]);

  return (
    <Flex
      flexDirection="column"
      zIndex={10}
      bottom="10px"
      width="100%"
      height="max-content"
      position="absolute"
      alignItems="center"
      justifyContent="flex-end"
      gap="10px"
      style={{ zIndex: 35 }}
    >
      {dotCount > 1 && (
        <Flex alignItems="center" justifyContent="center" gap="10px" width="100%" height="fit-content">
          {children}
        </Flex>
      )}
      {link && label && showButton && (
        <CarouselButton
          shape="rounded"
          showing={shouldShowButton}
          onClick={() => {
            if (link && label) {
              withWindow(global => {
                global.location.href = link;
              });
            }
          }}
        >
          {label}
        </CarouselButton>
      )}
    </Flex>
  );
};

type CarouselDotProps = {
  active?: boolean;
  index: number;
  onSelect?: (index: number) => void;
};

const CarouselDot = (props: CarouselDotProps) => {
  const { active = false, index, onSelect } = props;
  const size = '10px';
  const dotStyle = {
    width: size,
    height: size,
    borderRadius: size,
    backgroundColor: active ? SalePurpleColor : '#222222',
    opacity: active ? 0.95 : 0.6,
    cursor: 'pointer'
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      style={dotStyle}
      onClick={() => {
        onSelect?.(index);
      }}
    ></div>
  );
};

export default ContentCarousel;
