import React, { useRef, useEffect, ReactChildren, RefObject } from 'react';

const getRadialGradient = (xPerc: number, yPerc: number, intensity = 0.7, color = '255,255,255') => {
  return `radial-gradient(ellipse at ${xPerc}% ${yPerc}%, rgba(${color},${intensity}) 0%, rgba(${color},0) 99%)`;
};

const transforms = (x: number, y: number, el: HTMLDivElement, reverse: boolean = false) => {
  if (!(el && 'getBoundingClientRect' in el)) {
    return;
  }
  const constrain = 300;
  const perspective = 504;
  const box = el.getBoundingClientRect();

  let calcX = -(y - box.y - box.height / 2) / constrain;
  let calcY = (x - box.x - box.width / 2) / constrain;
  if (reverse) {
    calcX = (y - box.y - box.height / 2) / constrain;
    calcY = -(x - box.x - box.width / 2) / constrain;
  }
  return `perspective(${perspective}px) rotateX( ${calcX}deg) rotateY(${calcY}deg)`;
};

const shadows = (x: number, y: number, intensity: number = 0.3, el: HTMLElement) => {
  if (!(el && 'getBoundingClientRect' in el)) {
    return;
  }
  const constrain = 400;
  const box = el.getBoundingClientRect();
  const calcY = -(y - box.y - box.height / 2) / constrain;
  const calcX = (x - box.x - box.width / 2) / constrain;
  const offsetX = -(calcX * 10);
  const offsetY = calcY * 10;
  return `${offsetX}px  ${offsetY + 10}px ${15}px rgba(0,0,0,${intensity})`;
};

const highlights = (x: number, y: number, intensity: number, el: HTMLElement) => {
  if (!(el && 'getBoundingClientRect' in el)) {
    return;
  }
  const box = el.getBoundingClientRect();
  let centerPerc = 100 - Math.ceil(((x - box.x) / box.width) * 100);
  if (centerPerc < 10) {
    centerPerc = 10;
  } else if (centerPerc > 90) {
    centerPerc = 90;
  }

  let vertCenterPerc = 100 - Math.ceil(((y - box.y) / box.height) * 100);
  if (vertCenterPerc < 10) {
    vertCenterPerc = 10;
  } else if (vertCenterPerc > 90) {
    vertCenterPerc = 90;
  }

  return `${getRadialGradient(centerPerc, vertCenterPerc, intensity)}`;
};

const foilHighlights = (x: number, y: number, intensity: number, el: HTMLElement) => {
  if (!(el && 'getBoundingClientRect' in el)) {
    return;
  }
  const constrain = 100;
  const box = el.getBoundingClientRect();

  let centerPerc = 100 - Math.ceil(((x - box.x) / box.width) * 100);
  if (centerPerc < 10) {
    centerPerc = 10;
  } else if (centerPerc > 90) {
    centerPerc = 90;
  }

  let vertCenterPerc = 100 - Math.ceil(((y - box.y) / box.height) * 100);
  if (vertCenterPerc < 10) {
    vertCenterPerc = 10;
  } else if (centerPerc > 90) {
    vertCenterPerc = 90;
  }

  const leftPerc = centerPerc - 40;
  const rightPerc = centerPerc + 40;
  const calcY = -(y - box.y - box.height / 2) / constrain;
  const calcX = (x - box.x - box.width / 2) / constrain;

  const highlight = `${getRadialGradient(centerPerc, vertCenterPerc, intensity, '0,0,0')}, linear-gradient(${
    90 - (calcX + calcY * 10)
  }deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) ${leftPerc + 35}%, rgba(255,255,255,${intensity}) ${centerPerc}%, rgba(255,255,255,0) ${
    rightPerc - 35
  }%, rgba(255,255,255,0) 100%), linear-gradient(${90 - (calcX + calcY * 10)}deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) ${
    leftPerc + 39.9
  }%, rgba(255,255,255,1) ${centerPerc}%, rgba(255,255,255,0) ${rightPerc - 39.9}%, rgba(0,0,0,0) 100%)`;

  return highlight;
};

const transformElement = (el: HTMLDivElement, hiEl: HTMLDivElement | null | undefined, x: number, y: number, intensity: number = 0.5, el2: HTMLDivElement) => {
  if (el?.style) {
    const transform = transforms(x, y, el2);
    if (transform) {
      el.style.transform = transform;
    }

    el.style['filter'] = `drop-shadow( ${shadows(x, y, intensity, el2)})`;
  }
  if (hiEl?.style) {
    //@ts-ignore
    hiEl.style['background-image'] = foilHighlights(x, y, intensity, el2);
  }
};

const highlightElement = (hiEl: HTMLDivElement, x: number, y: number, intensity: number, el2: HTMLDivElement) => {
  if (hiEl?.style) {
    //@ts-ignore
    hiEl.style['background-image'] = highlights(x, y, intensity, el2);
  }
};

const getFoilMask = (maskUrl: string) => {
  return {
    WebkitMaskImage: `url(${maskUrl})`,
    maskImage: `url(${maskUrl})`,
    WebkitMaskSize: 'contain',
    maskSize: 'contain',
    maskMode: 'alpha',
    WebkitMaskRepeat: 'no-repeat',
    maskRepeat: 'no-repeat'
  };
};

const getShapeClippingPath = (shape: string | null | undefined, width: number, height: number, inset = 0) => {
  switch (shape) {
    case 'arch':
      return `inset(${inset}px round ${width / 2}px ${width / 2}px 0px 0px)`;
    case 'rounded':
      return `inset(${inset}px round ${width / 10}px ${width / 10}px ${width / 10}px ${width / 10}px)`;
    case 'oval':
      return `inset(${inset}px round ${height / 2}px ${height / 2}px ${height / 2}px ${height / 2}px)`;
    default:
      return `inset(${inset}px round 0px 0px 0px 0px)`;
  }
};

interface InteractiveCardProps {
  width: number;
  height: number;
  foil?: Maybe<string>;
  highlightIntensity?: Maybe<number>;
  foilColor?: Maybe<string>;
  foilIntensity?: number;
  texture?: Maybe<string>;
  textureIntensity?: Maybe<number>;
  children?: JSX.Element | ReactChildren;
  clipping?: Maybe<number>;
  shape?: Maybe<string>;
  link?: Maybe<string>;
}

export const InteractiveCard = (props: InteractiveCardProps) => {
  const {
    highlightIntensity = 0.2,
    foilColor = 'rgba(242, 175, 80, 1)',
    foilIntensity = 0.7,
    textureIntensity = 0.65,
    width = 2.5,
    height = 7.25,
    clipping = 0.25,
    shape = '',
    link,
    foil,
    texture,
    children
  } = props;
  const layerRef: RefObject<HTMLDivElement> = useRef(null);
  const highlightRef = useRef(null);
  const foilRef = useRef(null);

  const setVisualState = () => {
    if (layerRef.current) {
      window.requestAnimationFrame(function () {
        if (layerRef.current) {
          layerRef.current.style.transform = `perspective(${0}px) rotateX( ${0}deg) rotateY(${0}deg)`;
          layerRef.current.style.filter = `drop-shadow(0px  10px 15px rgba(0,0,0,${foilIntensity}))`;
        }
      });
    }
  };

  useEffect(() => {
    if (layerRef.current && highlightRef.current) {
      setVisualState();
      layerRef.current.onmousemove = function (e) {
        if (layerRef.current && highlightRef.current) {
          window.requestAnimationFrame(function () {
            if (layerRef.current) {
              if (highlightRef.current && highlightIntensity) {
                highlightElement(highlightRef.current, e.clientX, e.clientY, highlightIntensity, layerRef.current);
              }

              transformElement(layerRef.current, foilRef.current, e.clientX, e.clientY, foilIntensity, layerRef.current);
            }
          });
        }
      };

      layerRef.current.onmouseout = function (e) {
        setVisualState();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerRef.current, highlightRef.current, foilIntensity, highlightIntensity]);

  const handleOpenLink = () => {
    if (link && typeof link === 'string') {
      window.open(link, '_blank');
    }
  };
  const widthInPx = width;
  const heightInPx = height;
  const clipInPx = clipping ? clipping / 2 : 0;
  return (
    <div>
      <div
        ref={layerRef}
        onClick={link ? handleOpenLink : undefined}
        onKeyPress={link ? handleOpenLink : undefined}
        role={link ? 'button' : undefined}
        style={{
          backgroundColor: 'rgb(0,0,0,0)',
          height: `${heightInPx}px`,
          width: `${widthInPx}px`,
          position: 'relative',
          borderRadius: '0px',
          overflow: 'hidden',
          cursor: link ? 'pointer' : 'default'
        }}
      >
        <div
          style={{
            clipPath: getShapeClippingPath(shape, widthInPx, heightInPx, clipInPx),
            height: '100%',
            width: '100%'
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: 1
            }}
          >
            {children}
          </div>
          {texture && (
            <div
              style={{
                backgroundImage: `url(${texture})`,
                width: '100%',
                height: '100%',
                position: 'absolute',
                opacity: textureIntensity ?? 0.5,
                zIndex: 3
              }}
            ></div>
          )}
          {foil && foilColor && (
            <div
              ref={foilRef}
              style={{
                ...getFoilMask(foil),
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 4,
                backgroundColor: foilColor
              }}
            ></div>
          )}
          <div
            ref={highlightRef}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: 5
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
