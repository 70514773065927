import { BOX_SHADOW_16 } from '@apps/card/boxShadow';
import { templateToTitle } from '@apps/card/Card.utils';
import { StationeryTemplateCategoryEnum, useCardBackTemplatesByCategoryAndDimensionsQuery } from '@graphql/generated';
import { Box, Flex, TextV2 } from '@withjoy/joykit';
import React, { useEffect, useMemo } from 'react';
import { PageDimensions, ThemeJson } from '../CardCustomizer.types';
import { useCustomizationContext } from '../useCardCustomization';
import { ChoiceGroup } from './ChoiceGroup';
import { SkeletonThumbnail } from '@shared/components/Skeleton';
import { LayerData } from './Layer.types';

export const PLACEHOLDER_TEMPLATE = {
  fill: 'rgb(255,255,255)',
  layout: 'placeholder', // aka themeId
  layers: [] as LayerData[]
};

const TEMPLATE_ORDER = [
  'lets_celebrate',
  'photo_text',
  'photo_inset',
  'the_details',
  'kindly_rsvp',
  'photo_qr_code',
  'photo_monogram',
  'monogram',
  'photo_full',
  'typography',

  'adorable_thank_you',
  'many_thanks_thank_you',
  'monogram_thank_you',
  'names_thank_you',
  'photo_blank_thank_you',
  'photo_note_thank_you',

  'photo_note',
  'photo_note_red',
  'photo_note_blue',
  'photo_note_beige',
  'photo_note_full',
  'photo_note_full_red',
  'photo_note_full_blue',
  'photo_note_full_green',
  'large_photo',
  'large_photo_red',
  'large_photo_blue',
  'large_photo_green',
  'large_photo_tan',
  'duo',
  'duo_blue',
  'duo_red',
  'duo_green',
  'photo_booth',
  'photo_booth_snow',
  'collage',
  'photo_album',
  'all_photos',
  'gallery',
  'simple_message_red',
  'simple_message_blue',
  'simple_message_green',
  'simple_message',
  'plaid_red',
  'plaid_green',
  'plaid_blue',
  'snow_red',
  'snow_blue',
  'dots_red',
  'snow_gold',
  'dots_blue',
  'dots_green',
  'dots',
  'dots_cream',
  'blank_red',
  'blank_green',
  'blank_blue',

  'blank'
];

export interface StationeryTemplate {
  id: string;
  name?: string | null;
  themeId: string;
  themeJSON: ThemeJson;
  thumbnailUrl?: string | null;
}

// Some temporarily asymmetry with `themeId` in and `setTemplate` out.
// Ultimately we want to have `templateId` inbound.
// However this is blocked on us migrating existing drafts that are still stuck on `themeId`.
interface CardBackChoiceGroupProps {
  themeId: string;
  setTemplate: (template: StationeryTemplate) => void;
  dimensions: PageDimensions;
  price?: string;
}

export const CardBackChoiceGroup = (props: CardBackChoiceGroupProps) => {
  const { themeId, setTemplate, dimensions, price } = props;

  const [category] = useCustomizationContext(draft => draft.stationeryTemplateCategory);

  let normalizedCategory = category;
  // 'invitation' is also serving as the card back category for 'saveTheDate' cards.
  if (category === StationeryTemplateCategoryEnum.saveTheDate) normalizedCategory = StationeryTemplateCategoryEnum.invitation;

  const { data } = useCardBackTemplatesByCategoryAndDimensionsQuery({
    variables: {
      filter: {
        side: 'back',
        categories: [normalizedCategory],
        widthInches: dimensions.width,
        heightInches: dimensions.height
      }
    },
    batchMode: 'fast'
  });

  const templates = useMemo(() => {
    const sortedCopy = data?.stationeryTemplatesContainer.pagedResults.edges.map(edge => edge.node) ?? [];
    sortedCopy.sort((a, b) => {
      let indexA = TEMPLATE_ORDER.indexOf(a.themeId);
      let indexB = TEMPLATE_ORDER.indexOf(b.themeId);
      if (indexA === -1) indexA = Number.POSITIVE_INFINITY;
      if (indexB === -1) indexB = Number.POSITIVE_INFINITY;
      return indexA - indexB;
    });

    const indexOfUploadYourDesign = sortedCopy.findIndex(template =>
      [
        'upload_your_design',
        'upload_your_design_7x5',
        'upload_your_own', // deprecated, use `upload_your_design` instead
        'upload_your_own_design' // deprecated, use `upload_your_design` instead
      ].includes(template.themeId)
    );
    if (indexOfUploadYourDesign >= 0) {
      sortedCopy.splice(5, 0, ...sortedCopy.splice(indexOfUploadYourDesign, 1));
    }

    return sortedCopy;
  }, [data]);

  // New drafts are created with a placeholder template.
  // If we see the placeholder, we replace it with the default here.
  // Should only happen once per draft.
  useEffect(() => {
    if (themeId !== PLACEHOLDER_TEMPLATE.layout) return;
    const defaultTemplate = templates[0];
    if (!defaultTemplate) return;
    setTemplate(defaultTemplate);
  }, [setTemplate, templates, themeId]);

  if (!templates.length)
    return (
      <ChoiceGroup title="Layout" value={{ themeId }} price={price} getName={templateToTitle}>
        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" columnGap={3} rowGap={5} width="100%">
          <SkeletonThumbnail height={240} width="auto" />
          <SkeletonThumbnail height={240} width="auto" />
          <SkeletonThumbnail height={240} width="auto" />
          <SkeletonThumbnail height={240} width="auto" />
        </Box>
      </ChoiceGroup>
    );

  const selectedTemplate = templates.find(t => t.themeId === themeId) ?? templates[0];

  return (
    <>
      <ChoiceGroup title="Layout" value={selectedTemplate} choices={templates} price={price} setValue={setTemplate} getName={templateToTitle}>
        {templates.map((template, i) => {
          return (
            <Flex key={i} flexDirection="column" alignItems="center" rowGap={1}>
              <Box as="img" width="100%" boxShadow={BOX_SHADOW_16} src={template.thumbnailUrl ?? ''} />
              <TextV2 typographyVariant="label2" color="mono12" padding={2}>
                {templateToTitle(template)}
              </TextV2>
            </Flex>
          );
        })}
      </ChoiceGroup>
    </>
  );
};
