import { StationeryDraftFormat, useCreateStationeryDraftMutation } from '@graphql/generated';
import { CardDraftCreatedPageValue, usePublicCardShopTelemetry } from '../PublicShop.telemetry';
import { useHistory } from '@shared/core';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { useCallback } from 'react';
import { CustomizeTemplateData, MinimalRequiredTemplateDataForDraft, ValidDigitalDesignApiData } from '@apps/card/routes/Dashboard/components/DesignsGallery/DesignsGallery.types';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { withWindow } from '@shared/utils/withWindow';
import { useRedirectAfterTimeout } from './useRedirectAfterTimeout';
import { useConstructEcardEditorRedirectUrl } from '@apps/card/routes/Dashboard/components/DesignsGallery';

/**
 * Handle paper card customization redirects
 */
const useCreateCardDraftAndRedirectToEditor = (page: CardDraftCreatedPageValue) => {
  const { cardDraftCreated } = usePublicCardShopTelemetry();
  const { getCustomizeDraftPath, getDigitalCustomizeDraftPath } = useCardsRouterContext();
  const history = useHistory();

  const [callCreateDraft] = useCreateStationeryDraftMutation({
    onCompleted: data => {
      const { stationeryTemplate, id, format } = data.createStationeryDraft;
      cardDraftCreated({
        themeId: stationeryTemplate.themeId,
        stationeryTemplateCategory: stationeryTemplate.category,
        page,
        format: format === 'digital' ? 'digital' : 'paper'
      });

      const paperRoute = '/event/edit' + getCustomizeDraftPath(id);
      const digitalRoute = '/event/edit' + getDigitalCustomizeDraftPath(id);

      history.push(format === 'digital' ? digitalRoute : paperRoute);
    }
  });

  const createCardDraftAndRedirectToEditor = useCallback(
    (template: MinimalRequiredTemplateDataForDraft, format: StationeryDraftFormat) => {
      callCreateDraft({
        variables: {
          payload: {
            cardJSON: template.themeJSON,
            stationeryTemplateId: template.id,
            format
          }
        }
      });
    },
    [callCreateDraft]
  );

  return { createCardDraftAndRedirectToEditor };
};

const useRedirectToEcardEditor = (page: CardDraftCreatedPageValue) => {
  const constructEcardEditorRedirectUrl = useConstructEcardEditorRedirectUrl();
  const { cardDraftCreated } = usePublicCardShopTelemetry();
  const redirectAfterTimeout = useRedirectAfterTimeout();

  const redirectToEcardEditor = useEventCallback((template: ValidDigitalDesignApiData) => {
    cardDraftCreated({
      themeId: template.themeId,
      stationeryTemplateCategory: template.category,
      page,
      format: 'digital'
    });
    const redirectUrl = constructEcardEditorRedirectUrl(template.category, template.themeId);
    redirectAfterTimeout(redirectUrl);
  });

  return { redirectToEcardEditor };
};

export const useCreateDraftAndRedirectToEditor = (page: CardDraftCreatedPageValue) => {
  const { createCardDraftAndRedirectToEditor } = useCreateCardDraftAndRedirectToEditor(page);
  const { redirectToEcardEditor } = useRedirectToEcardEditor(page);
  const redirectAfterTimeout = useRedirectAfterTimeout();
  const { getConciergePath } = useCardsRouterContext();

  const createDraftAndRedirectToEditor = useEventCallback((data: CustomizeTemplateData) => {
    if (data.format === 'paper') {
      createCardDraftAndRedirectToEditor(data.template, StationeryDraftFormat.print);
    } else if (data.format === 'premiumDigital') {
      createCardDraftAndRedirectToEditor(data.template, StationeryDraftFormat.digital);
    } else if (data.format === 'concierge') {
      withWindow(global => {
        redirectAfterTimeout(global.location.origin + '/event/edit' + getConciergePath(data.template.identifier));
      });
    } else {
      redirectToEcardEditor(data.template);
    }
  });

  return { createDraftAndRedirectToEditor };
};
