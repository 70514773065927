import React from 'react';
import { useAuth } from '@shared/components/AuthProvider';
import { useMount } from '@shared/utils/hooks/useMount';
import { config } from '@static/js/joy';

export const SocialLoginComponent: React.FC = () => {
  const authProvider = useAuth();
  useMount(() => {
    authProvider.authorize(new URL('/account-redirect', config.clientUri).href, { enableSocialSignIn: true });
  });

  return null;
};
