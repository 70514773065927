import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { useCallback, useMemo } from 'react';
import { StationeryTemplate } from './DesignsGallery.types';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useHistory } from '@react-router';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { ProductOffering } from '../ConciergeServiceSection/ConciergeServiceSection.types';
import useConciergeTiles from './useConciergeTiles';
import { doesProductComponentMatch } from '@apps/card/routes/CardCustomizer/usePromotionConfig';
import { shuffle } from 'lodash-es';

export type PropsPayload = {
  props: PropAsset[];
  repeatTiles?: boolean;
  repeatTilesCategoryBlocklist?: StationeryTemplateCategoryEnum[];
  randomizeOrder?: boolean;
};

export type PropContent =
  | {
      type: 'image';
      responsiveTarget: 'desktop' | 'mobile';
      src: string;
    }
  | {
      type: 'video';
      responsiveTarget: 'desktop' | 'mobile';
      videoUrl: string;
      videoThumbnailUrl: string;
    };

export type PropAsset = {
  link?: string;
  label?: string;
  categoryList: string[];
  contentList: PropContent[];
  onClick?: () => void;
};

export type CardLinkAsset = {
  desktop: string;
  mobile: string;
  category: StationeryTemplateCategoryEnum;
  onClick?: () => void;
  titleText: string;
};

export type ConciergeTileAsset = ProductOffering & { index: number };

export type TileTypes =
  | (PropAsset & { type: 'prop' })
  | (StationeryTemplate & { type: 'template' })
  | (CardLinkAsset & { type: 'cardlink' })
  | (ConciergeTileAsset & { type: 'concierge' });

interface Arguments {
  category: StationeryTemplateCategoryEnum;
  templates: StationeryTemplate[];
  conciergeEnabled: boolean;
}

const isValidCategory = (value: unknown): value is StationeryTemplateCategoryEnum => {
  if (typeof value !== 'string') {
    return false;
  }

  const templateValues = Object.values(StationeryTemplateCategoryEnum);
  return templateValues.includes(value as StationeryTemplateCategoryEnum);
};

const isCategoryRepeatBlocklisted = (category: StationeryTemplateCategoryEnum, payload: PropsPayload) => {
  if (!payload.repeatTilesCategoryBlocklist) {
    return false;
  }

  return payload.repeatTilesCategoryBlocklist.includes(category);
};

const isPropContent = (value: unknown): value is PropContent => {
  if (!value) {
    return false;
  }

  if (typeof value !== 'object') {
    return false;
  }

  const content = value as PropContent;

  if (!Object.hasOwn(value, 'type')) {
    return false;
  }

  if (content.type !== 'image' && content.type !== 'video') {
    return false;
  }

  if (!Object.hasOwn(value, 'responsiveTarget')) {
    return false;
  }

  if (content.responsiveTarget !== 'desktop' && content.responsiveTarget !== 'mobile') {
    return false;
  }

  if (content.type === 'image') {
    if (!Object.hasOwn(value, 'src') && typeof content.src !== 'string') {
      return false;
    }
  } else if (content.type === 'video') {
    if (!Object.hasOwn(value, 'videoUrl') && typeof content.videoUrl !== 'string') {
      return false;
    }

    if (!Object.hasOwn(value, 'videoThumbnailUrl') && typeof content.videoThumbnailUrl !== 'string') {
      return false;
    }
  }

  return true;
};

const isValidProp = (value: unknown): value is PropAsset => {
  if (!value) {
    return false;
  }

  if (typeof value !== 'object') {
    return false;
  }

  const asset = value as PropAsset;

  if (Object.hasOwn(value, 'label')) {
    if (typeof asset.label !== 'string') {
      return false;
    }
    if (!Object.hasOwn(value, 'link') && typeof asset.link !== 'string') {
      return false;
    }
  }

  if (!Object.hasOwn(value, 'categoryList') && typeof asset.categoryList !== 'string') {
    return false;
  }

  if (!Object.hasOwn(value, 'contentList')) {
    return false;
  }

  if (!Array.isArray(asset.contentList)) {
    return false;
  }

  if (asset.contentList.some(content => !isPropContent(content))) {
    return false;
  }

  return true;
};

const isValidPropsPayload = (payload: unknown): payload is PropsPayload => {
  if (!payload) {
    return false;
  }

  if (typeof payload !== 'object') {
    return false;
  }

  const asPayload = payload as PropsPayload;

  if (!asPayload.props) {
    return false;
  }

  if (asPayload.props.some(el => !isValidProp(el))) {
    return false;
  }

  if (Object.hasOwn(payload, 'repeatTilesCategoryBlacklist')) {
    if (!Array.isArray(asPayload.repeatTilesCategoryBlocklist)) {
      return false;
    }

    if (asPayload.repeatTilesCategoryBlocklist?.some(category => !isValidCategory(category))) {
      return false;
    }
  }

  return true;
};

const cardCategoryMatches = (categoriesList: string[], context: { occasion: string; category: StationeryTemplateCategoryEnum }) => {
  return categoriesList.some(category => {
    const categoryComponents = category.split('/');
    const [targetOccasion, targetCategory] = categoryComponents;
    const { category: currentCategory, occasion: currentOccasion } = context;

    if (!doesProductComponentMatch(targetOccasion, currentOccasion)) {
      return false;
    }

    if (!doesProductComponentMatch(targetCategory, currentCategory)) {
      return false;
    }

    return true;
  });
};

const injectImagineCard = (templatesWithProps: TileTypes[], category: StationeryTemplateCategoryEnum, onClickCards: () => void) => {
  const imagineProps = [
    {
      desktop: 'https://withjoy.com/media/raw/print/savethedate-simple.gif',
      mobile: 'https://withjoy.com/media/raw/print/savethedate-simple.gif',
      category: StationeryTemplateCategoryEnum.saveTheDate,
      onClick: onClickCards,
      titleText: 'Imagine Your Card'
    },
    {
      desktop: 'https://withjoy.com/media/raw/print/invitation-fade-720x1008.gif',
      mobile: 'https://withjoy.com/media/raw/print/invitation-simple.gif',
      category: StationeryTemplateCategoryEnum.invitation,
      onClick: onClickCards,
      titleText: 'Imagine Your Card'
    }
  ];

  imagineProps.forEach(prop => {
    if (prop.category === category) {
      templatesWithProps.splice(2, 0, {
        ...prop!,
        type: 'cardlink'
      });
    }
  });
};

const usePropValues = (args: Arguments) => {
  const { category, templates, conciergeEnabled } = args;
  const { value: showImagineTab } = useFeatureValue('printImagineGalleryEnabled');
  const { value: showImagineProps } = useFeatureValue('printImagineShowValueProps');
  const { value: useNewProps, payload: newProps } = useFeatureValue('printValuePropsData');
  const history = useHistory();
  const { getCardImaginePath } = useCardsRouterContext();
  const injectConcierge = useConciergeTiles();

  const goToImagine = useCallback(() => {
    history.push(getCardImaginePath('wedding', category));
  }, [category, getCardImaginePath, history]);

  // row - number of cards per row, rowGap - number of empty rows between cards
  const [config] = useResponsive({
    values: {
      _: { row: 2, rowGap: 1 },
      sm2: { row: 3, rowGap: 1 },
      md3: { row: 4, rowGap: 1 },
      lg: { row: 5, rowGap: 1 },
      xl: { row: 6, rowGap: 1 }
    }
  });

  return useMemo(() => {
    const templatesWithProps: TileTypes[] = [...templates].map(t => ({
      ...t,
      type: 'template'
    }));

    let cards: PropAsset[] = [];

    const totalRows = templates.length / (config?.row ?? 1);
    const requiredPropsToFillGallery = totalRows / ((config?.rowGap ?? 1) + 1);

    // If adding more variants, don't forget to change === 'on' check.
    if (useNewProps === 'on' && isValidPropsPayload(newProps)) {
      const originalValuePropsList = newProps.props.filter(card => {
        return cardCategoryMatches(card.categoryList, {
          occasion: 'wedding',
          category
        });
      });

      cards = newProps.randomizeOrder ?? true ? shuffle(originalValuePropsList) : originalValuePropsList;

      if ((newProps.repeatTiles ?? false) && !isCategoryRepeatBlocklisted(category, newProps)) {
        if (cards.length < requiredPropsToFillGallery) {
          for (let i = cards.length; i < requiredPropsToFillGallery; i++) {
            cards.push(cards[i % originalValuePropsList.length]);
          }
        }
      }
    }

    const shouldInjectFeatured = showImagineTab === 'on' && showImagineProps === 'on';
    shouldInjectFeatured && injectImagineCard(templatesWithProps, category, goToImagine);

    cards.forEach((card, row) => {
      // number of cards to skip between placing value prop (currentRow * rowGap * cardsPerRow)
      const offset = config?.rowGap ? config.rowGap * config.row * row : 0;
      // position of value prop on the row (from 0 to config.row - 1)
      const positionOnRow = Math.floor(Math.random() * (config?.row || 0));
      // positionGlobal - global position of value prop in the array
      const positionGlobal = offset + row * (config?.row || 0) + positionOnRow + (config?.row || 0);

      templatesWithProps.splice(positionGlobal, 0, { ...card, type: 'prop' });
    });

    if (conciergeEnabled) {
      return injectConcierge(templatesWithProps, category);
    }

    return templatesWithProps;
  }, [templates, useNewProps, newProps, showImagineTab, showImagineProps, category, goToImagine, conciergeEnabled, config, injectConcierge]);
};

export default usePropValues;
