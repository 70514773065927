import React, { Children } from 'react';
import { PageScaler } from '../../components/PageScaler';
import { useSurface } from './useSurface';
import { Box } from '@withjoy/joykit';
import { CardDieCut } from '../../CardCustomizer.types';
import { cardDieCutToShapeConfig } from '@apps/card/Card.utils';
import { BOX_SHADOW_16 } from '@apps/card/boxShadow';
import { EditablePage } from '../../components/EditablePage';
import { LayerData } from '../../components/Layer.types';
import { ScopedUpdateHandler } from '../../useCardCustomization';
import { Page } from '../../components/Page';
import { StationeryDraftFormat } from '@graphql/generated';

// Sadly need to wrap the PageScaler just so we have access to `useSurface`.
const CardScaler: React.FC = ({ children }) => {
  const [, update] = useSurface();
  return (
    <PageScaler
      onScaleChange={scale =>
        update(draft => {
          draft.scale = scale;
        })
      }
    >
      {Children.only(children)}
    </PageScaler>
  );
};

// Unfortunately we can't have the an element clip it's children but not it's own box-shadow. So we need two elements.
export const Shadow: React.FC<{ shape: CardDieCut }> = ({ children, shape }) => {
  return (
    <Box borderRadius={cardDieCutToShapeConfig(shape)?.borderRadius} transition="border-radius .3s" boxShadow={BOX_SHADOW_16}>
      {Children.only(children)}
    </Box>
  );
};

interface EditableCardPagePreviewProps {
  shape: CardDieCut;
  page: { fill: string; layers: LayerData[] };
  update: ScopedUpdateHandler<{
    fill: string;
    layers: LayerData[];
  }>;
  pageKey?: string;
  format?: StationeryDraftFormat;
}

export const EditableCardPagePreview = ({ shape, pageKey, update, page, format }: EditableCardPagePreviewProps) => {
  return (
    <CardScaler>
      <Shadow shape={shape}>
        <EditablePage key={pageKey} width={5} height={7} page={page} shape={shape} update={update} format={format} />
      </Shadow>
    </CardScaler>
  );
};

interface NonEditableCardPagePreviewProps {
  shape: CardDieCut;
  page: { fill: string; layers: LayerData[] };
  enableShadow?: boolean;
  format?: StationeryDraftFormat;
}

export const NonEditableCardPagePreview = ({ page, shape, enableShadow, format }: NonEditableCardPagePreviewProps) => {
  const content = <Page width={5} height={7} page={page} shape={shape} format={format} />;
  return <PageScaler>{enableShadow ? <Shadow shape={shape}>{content}</Shadow> : content}</PageScaler>;
};
