import React from 'react';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { ProductOfferingDialog } from './ProductOfferingDialog';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { OnCustomizeOfferingClickHandler, ProductOffering } from './ConciergeServiceSection.types';
import { CustomizeNowClickOverrideHandler } from '../DesignsGallery/DesignsGallery.types';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { Box } from '@withjoy/joykit';
import { ProductOfferringCarousel } from './ProductOfferingCarousel';

const Container: React.FC = ({ children }) => {
  return (
    <Box style={{ overflowX: 'clip' }} paddingX={{ sm2: 5, md3: 6, lg1: 22, xl: 56, xxl: 7 }} maxWidth={'100vw'}>
      {children}
    </Box>
  );
};

export const ConciergeServiceSection = ({
  category,
  onCustomizeOfferingOverride,
  isOpen,
  onClose,
  selectedOffering,
  handleOnTileClick,
  enableCarousel
}: {
  category: StationeryTemplateCategoryEnum;
  onCustomizeOfferingOverride?: CustomizeNowClickOverrideHandler;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  selectedOffering: { selectedOfferingSortIndex: number | undefined; selectedOffering: ProductOffering | undefined };
  handleOnTileClick: (id: string) => void;
  enableCarousel: boolean;
}) => {
  const { goToConcierge } = useCardsRouterContext();

  const onCustomizeClick = useEventCallback<OnCustomizeOfferingClickHandler>(offering => {
    if (onCustomizeOfferingOverride) {
      onCustomizeOfferingOverride({ format: 'concierge', template: { ...offering, thumbnailUrl: offering.imageUrl } }, onClose);
    } else {
      goToConcierge(offering.identifier);
    }
  });

  return (
    <>
      {enableCarousel && (
        <Container>
          <ProductOfferringCarousel handleOnTileClick={handleOnTileClick} category={category} />
        </Container>
      )}
      <ProductOfferingDialog
        onCustomizeOfferingClick={onCustomizeClick}
        category={category}
        isOpen={isOpen}
        onClose={onClose}
        selectedOffering={selectedOffering.selectedOffering}
        offeringSortIndex={selectedOffering.selectedOfferingSortIndex!}
      />
    </>
  );
};

ConciergeServiceSection.Skeleton = ({ category }: { category: StationeryTemplateCategoryEnum }) => {
  return (
    <Container>
      <ProductOfferringCarousel.Skeleton category={category} />
    </Container>
  );
};
