import React from 'react';
import { ChoiceGroup } from '../../components/ChoiceGroup';
import { CardDieCut } from '../../CardCustomizer.types';
import { useCustomizationContext } from '../../useCardCustomization';
import { useEditingMode } from '../../useEditingMode';
import { useCardCustomizerTelemetry } from '../../CardCustomizer.telemetry';
import { Box } from '@withjoy/joykit';
import useGetCardDimensions from '@apps/card/routes/Dashboard/components/ProductDetailsPage/useGetCardDimensions';

interface SilhouetteChoiceGroupProps {
  onValueChange?: (value: CardDieCut) => void;
  price?: string;
  getOpacity?: (value: CardDieCut) => number;
}

export const SilhouetteChoiceGroup = ({ onValueChange, price, getOpacity }: SilhouetteChoiceGroupProps) => {
  const { mode } = useEditingMode();

  const [general, update] = useCustomizationContext(draft => draft.config.customizations, mode === 'order');
  const { shape } = general;

  const cardDimensions = useGetCardDimensions();
  const is5x7 = cardDimensions?.width === 5 && cardDimensions.height === 7;

  const { customizationButtonInteracted } = useCardCustomizerTelemetry();

  return (
    <ChoiceGroup<CardDieCut>
      title="Silhouette"
      value={shape}
      price={price}
      choices={[CardDieCut.rectangle, CardDieCut.rounded, ...(is5x7 ? [CardDieCut.arch] : [])]}
      setValue={value => {
        update(draft => {
          draft.shape = value;
        });
        onValueChange && onValueChange(value);
        customizationButtonInteracted({ property: 'shapeOption', value });
      }}
      getOpacity={getOpacity}
    >
      <Box borderColor="mono14" borderStyle="solid" borderWidth="1px" width={32} aspectRatio="5/7" />
      <Box borderColor="mono14" borderStyle="solid" borderWidth="1px" width={32} aspectRatio="5/7" borderRadius={8} />
      {is5x7 && <Box borderColor="mono14" borderStyle="solid" borderWidth="1px" width={32} aspectRatio="5/7" borderTopLeftRadius={32} borderTopRightRadius={32} />}
    </ChoiceGroup>
  );
};
