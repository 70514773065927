import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';

interface TextRevisionMessageProps {
  hideMessage?: boolean;
}

const TextRevisionMessage: React.FC<TextRevisionMessageProps> = ({ hideMessage }) => {
  return (
    <Flex
      zIndex={1}
      paddingX={9}
      paddingY={7}
      position="absolute"
      bottom="0"
      top="0"
      left="0"
      right="0"
      flexDirection="column"
      justifyContent="flex-start"
      backdropFilter="blur(3px)"
      backgroundColor="rgba(255, 255, 255, 0.1)"
    >
      {!hideMessage && (
        <Flex flexDirection="column" padding={5} gap={2} backgroundColor="mono14" borderRadius={3}>
          <TextV2 typographyVariant="button1" color="white">
            Text Revisions Mode
          </TextV2>
          <TextV2 typographyVariant="label2" color="white">
            It allows you to fix typographical errors. You can edit all text fields on the card and move elements around. At this point, you cannot change the card specifications.
          </TextV2>
        </Flex>
      )}
    </Flex>
  );
};

export default TextRevisionMessage;
