import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { ConciergeTileAsset } from '../../../usePropValues';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { GalleryTile } from '../../../../GalleryTile';
import { Box } from '@withjoy/joykit';
import { BOX_SHADOW_60_02, BOX_SHADOW_8 } from '@apps/card/boxShadow';
import { animationTransition } from '@shared/utils/animationTransition';
import { useVimeoPlayerContext } from '@apps/registry/common/components/Catalog/utils/vimeoPlayer';
import Player from '@vimeo/player';
import { maybeApplyCallback } from '@shared/utils/maybeApplyCallback';
import { CardEventType } from '../GalleryItem';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { PRODUCT_OFFERINGS } from '../../../../ConciergeServiceSection/ConciergeServiceSection.config';
import { useCardCategoryContext } from '../../../../CardCategoryProvider';

type CardTileConciergeProps = {
  data: ConciergeTileAsset & { type: 'concierge' };
  onSelect: (event: CardEventType) => void;
};

export const VimeoContainer = ({ r, src, onLoad }: { r?: RefObject<HTMLIFrameElement>; src: string; onLoad: () => void }) => {
  useEffect(onLoad, [onLoad]);
  return <Box ref={r} src={src} border="none" outline="none" width="100%" height="100%" position="absolute" top={0} left={0} title="d" loading="lazy" as="iframe"></Box>;
};

export const CardTileConcierge = (props: CardTileConciergeProps) => {
  const isMobileOrTablet = useIsMobileOrTablet();
  const { data, onSelect } = props;
  const { shortName, costPerHundredString, imageUrl, videoThumbnailUrl, videoUrl: videoUrlProp, index: sortIndex } = data;

  const { VimeoPlayer, prepareForUse } = useVimeoPlayerContext();
  const placeholderImageRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<HTMLIFrameElement>(null);
  const [videoPlayer, setVideoPlayer] = useState<Player | null>(null);
  const [hasInteracted, setHasInteracted] = useState(false);

  const { paperlustConciergeGalleryTileInteracted } = useCardTelemetry();
  const { currentCategory } = useCardCategoryContext();

  const videoUrl = useMemo(() => {
    const url = new URL(videoUrlProp);
    const searchParams = url.searchParams;
    searchParams.append('muted', '1');
    searchParams.append('loop', '1');
    searchParams.append('background', '1');
    return url.href;
  }, [videoUrlProp]);

  const showAndPlay = (player?: Player) => {
    const video = player ?? videoPlayer;
    if (hasInteracted && video?.getPaused()) {
      video.play().catch(() => {
        // Ignore the error because it's expected when the video is forcibly paused
      });
      placeholderImageRef.current?.style.setProperty('opacity', '0');
    }
  };

  const onPlayerLoad = () => {
    if (playerRef && playerRef.current && VimeoPlayer && !videoPlayer) {
      const player = new VimeoPlayer(playerRef.current);
      setVideoPlayer(player);
      showAndPlay(player);
    }
  };

  useEffect(() => {
    prepareForUse();
  }, [prepareForUse]);

  const onMouseOver = () => {
    if (videoPlayer && hasInteracted) {
      showAndPlay();
    } else if (!hasInteracted) {
      setHasInteracted(true);
    }
  };

  const onMouseExit = () => {
    placeholderImageRef.current?.style.setProperty('opacity', '1');
    if (videoPlayer && hasInteracted) {
      videoPlayer.pause();
      videoPlayer.setCurrentTime(0);
    }
  };

  return (
    <GalleryTile
      onClick={() => {
        paperlustConciergeGalleryTileInteracted({
          suite: data.longName,
          cost: data.costPerHundredString,
          sortIndex,
          orderOfItems: PRODUCT_OFFERINGS[currentCategory].map(offering => offering.longName)
        });
        onSelect({ id: data.id, type: 'conciergeselectedevent' });
        onMouseExit();
      }}
    >
      <GalleryTile.AssetPreviewFrame>
        <Box position="relative" width="100%">
          <Box
            onMouseOver={maybeApplyCallback(!isMobileOrTablet, onMouseOver)}
            onMouseOut={maybeApplyCallback(!isMobileOrTablet, onMouseExit)}
            borderRadius={1}
            boxShadow={BOX_SHADOW_8}
            height="100%"
            overflow="hidden"
            position="relative"
            aspectRatio="5 / 7"
            transition={animationTransition('box-shadow')}
            _groupHover={{
              boxShadow: `${BOX_SHADOW_8},${BOX_SHADOW_60_02}`
            }}
          >
            {!isMobileOrTablet && hasInteracted && (
              <Box
                position="relative"
                paddingTop="177.78%"
                backgroundColor="mono1"
                backgroundPosition="center"
                backgroundSize="cover"
                backgroundRepeat="no-repeat"
                backgroundImage={`url('${videoThumbnailUrl}')`}
              >
                <VimeoContainer onLoad={onPlayerLoad} r={playerRef} src={videoUrl} />
              </Box>
            )}
            <Box
              ref={placeholderImageRef}
              position="absolute"
              transition={animationTransition('opacity')}
              top={0}
              left={0}
              backgroundImage={`url('${imageUrl}')`}
              backgroundPosition="center"
              backgroundSize={'cover'}
              size="100%"
            />
          </Box>
        </Box>
      </GalleryTile.AssetPreviewFrame>
      <GalleryTile.Content title={shortName} label={`From ${costPerHundredString}`}>
        <GalleryTile.Tag variant={'printAvailable'} />
      </GalleryTile.Content>
    </GalleryTile>
  );
};
