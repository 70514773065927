import React from 'react';
import { PropContent } from '../../../../../usePropValues';
import { Box } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';

type CarouselImageContainerProps = {
  asset: PropContent;
  currentPosition: number;
  onClick?: () => void;
};

export const CarouselImageContainer = (props: CarouselImageContainerProps) => {
  const { asset, onClick, currentPosition } = props;

  if (asset.type !== 'image') {
    return null;
  }

  return (
    <Box
      position="absolute"
      top="0px"
      left="0px"
      overflow="hidden"
      transition={animationTransition('transform')}
      zIndex={20}
      transform={`translateX(${100 * currentPosition}%)`}
      as="img"
      width="100%"
      src={asset.src}
      justifySelf="center"
      onClick={onClick}
      cursor={onClick ? 'pointer' : undefined}
    />
  );
};
