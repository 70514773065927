import { useTranslation } from '@shared/core';
import { Invitation, Share, Website, MailingAddress, Diamond, Mobile } from '@withjoy/joykit/icons';
import React, { useMemo } from 'react';
import { CustomizeNowButton } from '../../CustomizeNowButton';
import { ListValueProps } from '../ValueProps/ValueProps';
interface PremiumDigitalCustomizationPaneProps {
  onCustomizeClick: () => void;
  isCreatingDraft: boolean;
  intersectionRef: React.RefObject<HTMLButtonElement>;
  hideCustomizeNowButton?: boolean;
  disableCustomizeNowButton?: boolean;
}

export const PremiumDigitalCustomizationPane = (props: PremiumDigitalCustomizationPaneProps) => {
  const { disableCustomizeNowButton, onCustomizeClick, hideCustomizeNowButton, isCreatingDraft, intersectionRef } = props;
  const { t2 } = useTranslation('stationery');

  const { listValueProps } = useMemo(() => {
    const { listValueProps } = t2('pdpModal', 'customizationPane', 'premiumDigitalTemplateConfig');
    return {
      listValueProps: {
        sectionTitle: listValueProps.sectionTitle,
        items: [
          {
            icon: <Diamond size={24} alignSelf="flex-start" />,
            title: listValueProps.premium.title,
            description: listValueProps.premium.description
          },
          {
            icon: <Website size={24} alignSelf="flex-start" />,
            title: listValueProps.matchingPaperCards.title,
            description: listValueProps.matchingPaperCards.description
          },
          {
            icon: <Mobile size={24} alignSelf="flex-start" />,
            title: listValueProps.internationalMessage.title,
            description: listValueProps.internationalMessage.description
          },
          {
            icon: <Invitation size={24} alignSelf="flex-start" />,
            title: listValueProps.personalizedEmail.title,
            description: listValueProps.personalizedEmail.description
          },
          {
            icon: <Share size={24} alignSelf="flex-start" />,
            title: listValueProps.shareableLink.title,
            description: listValueProps.shareableLink.description
          },
          {
            icon: <MailingAddress size={24} alignSelf="flex-start" />,
            title: listValueProps.contactCollector.title,
            description: listValueProps.contactCollector.description
          }
        ]
      }
    };
  }, [t2]);

  return (
    <>
      <CustomizeNowButton
        ref={intersectionRef}
        hide={hideCustomizeNowButton}
        isLoading={isCreatingDraft}
        onCustomizeClick={onCustomizeClick}
        disabled={disableCustomizeNowButton}
        marginBottom={6}
      />
      <ListValueProps items={listValueProps.items} sectionTitle={listValueProps.sectionTitle} />
    </>
  );
};
