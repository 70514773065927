import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { PRODUCT_OFFERINGS } from '../ConciergeServiceSection/ConciergeServiceSection.config';
import { TileTypes } from './usePropValues';
import { useCallback } from 'react';

// Index of first Concierge card
const FIRST_CONCIERGE_INDEX = 10;
// Base interval between cards
const CONCIERGE_INTERVAL = 7;
// + variation from CONCIERGE_INTERVAL
const CONCIERGE_VARIATION = 2;
const useConciergeTiles = () => {
  const injectTiles = useCallback((tiles: TileTypes[], category: StationeryTemplateCategoryEnum) => {
    const tilesWithConcierge = [...tiles].map(t => t);

    const products = PRODUCT_OFFERINGS[category];
    let insertionIndex = FIRST_CONCIERGE_INDEX;
    products.forEach((product, productsIndex) => {
      const variance = Math.floor(Math.random() * 10) % (CONCIERGE_VARIATION + 1);
      if (productsIndex !== 0) {
        insertionIndex += CONCIERGE_INTERVAL + variance;
      }
      tilesWithConcierge.splice(insertionIndex, 0, { ...product, index: productsIndex, type: 'concierge' });
    });

    return tilesWithConcierge;
  }, []);

  return injectTiles;
};

export default useConciergeTiles;
