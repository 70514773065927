import marketingFonts, { MarketingFontFamily } from '@shared/utils/fonts/marketingFonts';
import { styled, Stack } from '@withjoy/joykit';

export const CenteredStack = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PageTitle = styled.h1`
  ${marketingFonts.CalloutHed32Bold};
`;

// desired all font-families on form are Akkurat
const fontFamily: MarketingFontFamily = 'Akkurat LL';
export const SignInContent = styled.div`
  width: 480px;
  max-width: 100%;
  background-color: #fff;
  margin: auto;
  border-radius: 12px;
  & * {
    font-family: ${fontFamily};
  }
`;

export const SubtleLink = styled.a`
  color: ${props => props.theme.colors.primary4};
  &:hover {
    color: ${props => props.theme.colors.primary4};
  }
`;

export const StyledPrivacyNoticy = styled.div``;
