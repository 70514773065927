import React, { useMemo } from 'react';
import { Flex } from '@withjoy/joykit';
import { useHistory } from '@shared/core';
import { useReactiveVar } from '@apollo/client';
import { activePrintFiltersVar } from '../../utils/activePrintFiltersCache';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

import { useFormattedPrintFiltersFacetHref } from '../../useFormattedPrintFiltersFacetHref';
import { FilterPill } from './components/FilterPill';
import { FORMAT_DIGITAL_COMPOSITE_CODE, FORMAT_GROUP_CODE, FORMAT_PAPER_DIGITAL_COMPOSITE_CODE } from './Filters.constants';
import { FilterFacet } from './Filter.types';
import { useCardTelemetry } from '@apps/card/Card.telemetry';

interface FiltersProps {
  filterMatchCounts: Record<string, number>;
}

export const Filters = React.memo((props: FiltersProps) => {
  const { filterMatchCounts } = props;
  const history = useHistory();
  const activePrintFilters = useReactiveVar(activePrintFiltersVar);

  const { filterFacetClicked } = useCardTelemetry();

  const { deriveHref } = useFormattedPrintFiltersFacetHref();

  const facets: Array<FilterFacet> = useMemo(() => {
    return [
      {
        groupCode: FORMAT_GROUP_CODE,
        label: 'Format',
        options: [
          {
            compositeCode: FORMAT_PAPER_DIGITAL_COMPOSITE_CODE,
            isActive: !!activePrintFilters[FORMAT_PAPER_DIGITAL_COMPOSITE_CODE],
            label: 'Paper & Digital',
            matchingItemCount: filterMatchCounts[FORMAT_PAPER_DIGITAL_COMPOSITE_CODE]
          },
          {
            compositeCode: FORMAT_DIGITAL_COMPOSITE_CODE,
            isActive: !!activePrintFilters[FORMAT_DIGITAL_COMPOSITE_CODE],
            label: 'Digital Only',
            matchingItemCount: filterMatchCounts[FORMAT_DIGITAL_COMPOSITE_CODE]
          }
        ]
      }
    ];
  }, [activePrintFilters, filterMatchCounts]);

  const handleOnFilterChange = useEventCallback<React.ChangeEventHandler<HTMLInputElement>>(evt => {
    const { target } = evt;

    if (!target.disabled && target.dataset.itemId) {
      filterFacetClicked({ facet: 'format', code: target.dataset.itemId, isChecked: target.checked });
      history.push(deriveHref(target.dataset.itemId, target.checked));
    }
  });

  const filteredFacets = useMemo(() => {
    return facets.filter(facet => facet.options.filter(option => option.matchingItemCount > 0).length > 1);
  }, [facets]);

  if (filteredFacets.length === 0) {
    return null;
  }

  return (
    <Flex>
      {facets.map(facet => (
        <FilterPill key={facet.groupCode} facet={facet} onFilterChange={handleOnFilterChange} />
      ))}
    </Flex>
  );
});
