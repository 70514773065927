import React, { useRef, useState } from 'react';
import { CardPreviewPane } from './components/CardCustomizationContent/CardPreviewPane';
import { CardFormatControl, SimpleCardPdpModalProps as ProductDetailsPageProps, StationeryTemplate } from './ProductDetailsPage.types';
import { enrichCardTelemetryExtraInfo, useCardTelemetry } from '@apps/card/Card.telemetry';
import { CardFormat } from './constants';
import { useStickyCustomizeButton } from './useStickyCustomizeButton';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';

import { DigitalDesignTemplate, usePdpData } from './usePdpData';
import { CardCustomizationPane } from './components/CardCustomizationContent/CardCustomizationPane';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { DraftData } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { TitleBlock } from './components/TitleBlock';
import { DigitalCustomizationPane } from './components/DigitalCustomizationContent/DigitalCustomizationPane';
import { DigitalPreviewPane } from './components/DigitalCustomizationContent/DigitalPreviewPane';
import { templateToTitle } from '@apps/card/Card.utils';
import { PdpLayout } from './components/PdpLayout/PdpLayout';
import { usePriceSummary } from '@apps/card/components/PriceSummary/PriceSummary';
import { SkeletonGroup } from '@shared/components/Skeleton';
import { useCustomizeClickHandler } from './useCustomizeClickHandler';
import { Format, MinimalRequiredTemplateDataForDraft } from '../DesignsGallery/DesignsGallery.types';
import { useCardCategoryContext } from '../CardCategoryProvider';
import { useFeatureValue } from '@shared/core/featureFlags';
import { PremiumDigitalPdp } from './PremiumDigitalPdp';

interface RootProps {
  eventId?: string;
  template: StationeryTemplate;
  digitalDesignTemplate: DigitalDesignTemplate | null;
  templateCategory: StationeryTemplateCategoryEnum;
  themeId: string;
  designName: string;
  onCustomizeCardClickOverride?: ProductDetailsPageProps['onCustomizeCardClickOverride'];
}

export interface BasePdpImplementationProps {
  themeId: string;
  templateCategory: StationeryTemplateCategoryEnum;
  isCreatingDraft: boolean;
  cardFormatControl: CardFormatControl;
  /**
   * Should be applied to the element that will determine when to show
   * the customize button now in the header.
   */
  intersectionRef: React.RefObject<HTMLButtonElement>;
  showCustomizeButtonInHeader: boolean;
  hideCustomizeButtonInCustomizationPane: boolean;
  designName: string;
}
interface PaperPdpProps extends BasePdpImplementationProps {
  template: StationeryTemplate;
  onPaperCustomization: (draft: DraftData) => void;
  onCustomizeClick: (template: MinimalRequiredTemplateDataForDraft, subtotalInMinorUnits?: number) => void;
}
const PaperPdp = (props: PaperPdpProps) => {
  const {
    cardFormatControl,
    intersectionRef,
    isCreatingDraft,
    onCustomizeClick,
    onPaperCustomization,
    showCustomizeButtonInHeader,
    template,
    themeId,
    designName,
    templateCategory
  } = props;

  const themeJson = template.themeJSON;

  const isMobileOrTablet = useIsMobileOrTablet();
  const { value: printEnableDigitalEditor } = useFeatureValue('printEnableDigitalEditor', { skip: !(template.premium || cardFormatControl.supportedTemplateFormats === 'paper') });

  const { total, totalInMinorUnits } = usePriceSummary({ templateCategory, priceUpTo: 'cardFront', cardConfig: template.themeJSON });

  const handleOnCustomizeNowClick = useEventCallback(() => {
    onCustomizeClick(template, totalInMinorUnits);
  });

  const isTemplatePremium = printEnableDigitalEditor === 'on';

  return (
    <PdpLayout
      designName={designName}
      templateCategory={templateCategory}
      isPremium={template.premium}
      onCustomizeNowClick={handleOnCustomizeNowClick}
      isCreatingDraft={isCreatingDraft}
      cardFormatControl={cardFormatControl}
      subtotal={total}
      showCustomizeButtonInHeader={showCustomizeButtonInHeader}
      titleBlock={() => <TitleBlock title={designName} />}
      previewPane={() => (
        <CardPreviewPane
          isTemplatePremium={isTemplatePremium}
          templateCategory={templateCategory}
          cardFront={themeJson.card.front}
          cardShape={themeJson.customizations.shape}
          thumbnailUrl={template.thumbnailUrl!}
        />
      )}
      customizationPane={() => (
        <CardCustomizationPane
          hideCustomizeNowButton={isMobileOrTablet}
          intersectionRef={intersectionRef}
          customizationConfig={themeJson}
          isCreatingDraft={isCreatingDraft}
          themeId={themeId}
          themeName={designName}
          stationeryTemplateCategory={templateCategory}
          onCardCustomizeClick={handleOnCustomizeNowClick}
          onCardCustomization={onPaperCustomization}
        />
      )}
    />
  );
};

interface DigitalPdpProps extends BasePdpImplementationProps {
  template: DigitalDesignTemplate;
  onCustomizeDigitalClick: () => void;
  disableCustomizeNowButton?: boolean;
}

const DigitalPdp = (props: DigitalPdpProps) => {
  const {
    cardFormatControl,
    disableCustomizeNowButton,
    intersectionRef,
    isCreatingDraft,
    onCustomizeDigitalClick,
    showCustomizeButtonInHeader,
    template,
    designName,
    templateCategory
  } = props;

  const isMobileOrTablet = useIsMobileOrTablet();

  return (
    <PdpLayout
      designName={designName}
      templateCategory={templateCategory}
      onCustomizeNowClick={onCustomizeDigitalClick}
      isCreatingDraft={isCreatingDraft}
      cardFormatControl={cardFormatControl}
      disableCustomizeNowButton={disableCustomizeNowButton}
      showCustomizeButtonInHeader={showCustomizeButtonInHeader}
      titleBlock={() => <TitleBlock title={designName} />}
      previewPane={() => <DigitalPreviewPane digitalDesignTemplate={template} />}
      customizationPane={() => (
        <DigitalCustomizationPane
          hideCustomizeNowButton={isMobileOrTablet}
          intersectionRef={intersectionRef}
          isCreatingDraft={isCreatingDraft}
          onCustomizeClick={onCustomizeDigitalClick}
          disableCustomizeNowButton={disableCustomizeNowButton}
        />
      )}
    />
  );
};

const Root = (props: RootProps) => {
  const { eventId, onCustomizeCardClickOverride, template, templateCategory, digitalDesignTemplate, themeId, designName } = props;

  const supportedTemplateFormats: Format = template && digitalDesignTemplate ? 'paperAndDigital' : template ? 'paper' : 'digital';

  const { pdpFormatControlClicked } = useCardTelemetry({ page: 'productPdpModal' });

  const [cardFormat, setCardFormat] = useState(template ? CardFormat.paper : CardFormat.digital);

  const [storedTemplate, setStoredTemplate] = useState<StationeryTemplate | null>(template);

  const { pricePerCard } = usePriceSummary({ templateCategory, priceUpTo: 'cardFront', cardConfig: template.themeJSON });

  const handleOnPaperCustomization = useEventCallback((draft: DraftData) => {
    setStoredTemplate(prev =>
      prev
        ? {
            ...prev,
            themeJSON: draft.config
          }
        : null
    );
  });

  const { isCreatingDraft, onCustomizeClick } = useCustomizeClickHandler({
    currentCategory: templateCategory,
    eventId,
    onCustomizeCardClickOverride,
    supportedFormats: supportedTemplateFormats
  });

  const handleOnCustomizeDigitalClick = useEventCallback(() => {
    if (digitalDesignTemplate) {
      onCustomizeClick('digital', digitalDesignTemplate, supportedTemplateFormats);
    }
  }, [template, digitalDesignTemplate]);

  const isMobileOrTablet = useIsMobileOrTablet();
  // Will be attached to the `CustomizeNowButton` in the customization pane
  // Used to determine when to show the button in the header
  const intersectionRef = useRef<HTMLButtonElement>(null);
  const shouldShowCustomizeButtonInHeader = useStickyCustomizeButton(intersectionRef, !isMobileOrTablet);

  const handleSetCardFormat = useEventCallback((format: CardFormat) => {
    setCardFormat(format);
    pdpFormatControlClicked({ themeId, format });
  });

  const cardFormatControl: Maybe<CardFormatControl> = {
    cardFormat,
    onChange: handleSetCardFormat,
    cardFormatPrice: `From ${pricePerCard}`,
    supportedTemplateFormats
  };

  const basePdpProps: BasePdpImplementationProps = {
    cardFormatControl,
    designName,
    intersectionRef,
    isCreatingDraft,
    showCustomizeButtonInHeader: shouldShowCustomizeButtonInHeader,
    hideCustomizeButtonInCustomizationPane: isMobileOrTablet,
    templateCategory,
    themeId
  };

  if (cardFormat === CardFormat.paper && storedTemplate) {
    return (
      <PaperPdp
        template={storedTemplate}
        onPaperCustomization={handleOnPaperCustomization}
        onCustomizeClick={() => onCustomizeClick('paper', storedTemplate, supportedTemplateFormats)}
        {...basePdpProps}
      />
    );
  } else if (cardFormat === CardFormat.premiumDigital && storedTemplate) {
    return <PremiumDigitalPdp template={storedTemplate} onCustomizeClick={() => onCustomizeClick('premiumDigital', storedTemplate, supportedTemplateFormats)} {...basePdpProps} />;
  } else if (cardFormat === CardFormat.digital && digitalDesignTemplate) {
    return <DigitalPdp template={digitalDesignTemplate} onCustomizeDigitalClick={handleOnCustomizeDigitalClick} {...basePdpProps} />;
  } else {
    return null;
  }
};

export const ProductDetailsPage = (props: ProductDetailsPageProps) => {
  const { currentCategory } = useCardCategoryContext();
  const { themeId } = props;
  const { template, digitalTemplate, loading } = usePdpData(themeId, currentCategory, true);

  enrichCardTelemetryExtraInfo({ stationeryTemplateCategory: currentCategory });

  // Extracting design name here for consistency between pdps
  const designName = (template && templateToTitle(template)) || digitalTemplate?.name || '';

  return (
    <SkeletonGroup isReady={!loading} placeholder={<PdpLayout.Skeleton />} width="100%">
      {template ? <Root template={template} digitalDesignTemplate={digitalTemplate} designName={designName} templateCategory={currentCategory} {...props} /> : null}
    </SkeletonGroup>
  );
};
