import { Flex, FlexProps, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { PAGE_SECTION_HORIZONTAL_PADDING } from '../../Dashboard.constants';

interface PageSectionProps {
  id: string;
  title?: string | (() => JSX.Element);
  children?: React.ReactElement;
  spacing?: FlexProps['rowGap'];
}

export const PageSection = (props: PageSectionProps) => {
  const { children, id, title, spacing } = props;
  return (
    <Flex id={id} flexDirection="column" rowGap={spacing ?? { _: 6, md: 8 }} paddingX={PAGE_SECTION_HORIZONTAL_PADDING} marginTop={8}>
      {title ? (
        typeof title === 'string' ? (
          <TextV2 typographyVariant={{ _: 'hed4', md: 'display1' }} fontWeight={{ _: 600, md: 600 }}>
            {title}
          </TextV2>
        ) : (
          title()
        )
      ) : null}
      {children}
    </Flex>
  );
};

interface HeaderProps {
  title: string;
  description: string;
}
const Header = ({ title, description }: HeaderProps) => {
  return (
    <Flex marginBottom={3} flexDirection="column" justifyContent="center" alignItems="center" rowGap={3} textAlign="center">
      <TextV2 typographyVariant={{ _: 'hed4', sm2: 'display1' }} fontWeight={{ _: 600, sm2: 600 }}>
        {title}
      </TextV2>
      <TextV2 paddingX={6} typographyVariant="body3" width="481px" maxWidth="100%">
        {description}
      </TextV2>
    </Flex>
  );
};

PageSection.Header = Header;

PageSection.displayName = 'PageSection';
