import React from 'react';
import { LinkV1, ButtonV1, InputV1, Stack, FormField, TextV1, Flex } from '@withjoy/joykit';
import { useSignInController } from './SignIn.controller';
import { SignInContent, CenteredStack } from '../CommonStyles';
import { ProviderSettings } from '@apps/account/routes/SignIn/SignInPage.controller';
import { TermsAndPrivacyAgreement } from '../TermsAndPrivacyAgreement';
import { SignInUp3rdPartyProviders } from '../SignInUp3rdPartyProviders';
import { useTranslation } from '@shared/core';
import { ErrorMessage } from './SignIn.styles';

export interface Props
  extends Readonly<{
    providers?: ProviderSettings;
    redirectUri?: string;
    signUpInstead: () => void;
  }> {}

export const SignIn: React.FC<Props> = props => {
  const { providers = {}, redirectUri, signUpInstead } = props;
  const { t2 } = useTranslation('account');
  const tSignin = t2('signin');
  const { formik, thirdPartyClickHandler, loading, submitError } = useSignInController(redirectUri);
  return (
    <SignInContent>
      <CenteredStack spacing={5} margin={6}>
        <TextV1 component="h2" variant="hed6">
          {tSignin.signInToJoy}
        </TextV1>
        <div>
          {tSignin.donthaveaccount} <LinkV1 onClick={signUpInstead}>{tSignin.createAnAccount}</LinkV1>
        </div>
      </CenteredStack>
      {providers.google || providers.facebook ? <SignInUp3rdPartyProviders providers={providers} mode="signin" onThirdPartyClick={thirdPartyClickHandler} /> : null}
      {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
      <form onSubmit={formik.submitForm}>
        <Stack spacing={6}>
          {(
            <>
              <FormField label="" error={formik.errors.email && formik.touched.email ? formik.errors.email : undefined}>
                <InputV1 placeholder={tSignin.enterEmail} autoComplete="username" {...formik.getFieldProps('email')} iconRight="email" />
              </FormField>
              <FormField error={formik.errors.password && formik.touched.password ? formik.errors.password : undefined} label="">
                <InputV1 type="password" placeholder={tSignin.enterPassword} autoComplete="current-password" {...formik.getFieldProps('password')} iconRight="lock" />
              </FormField>
              <Flex justifyContent="center">
                <ButtonV1 fill={true} type="submit" onClick={formik.submitForm} loading={loading}>
                  {tSignin.signin}
                </ButtonV1>
              </Flex>
            </>
          ) || null}
          <TermsAndPrivacyAgreement />
        </Stack>
      </form>
    </SignInContent>
  );
};
