import React, { useCallback } from 'react';
import { Gallery } from '../Gallery';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { Skeleton, SkeletonGroup } from '@shared/components/Skeleton';
import { useTranslation } from '@shared/core';
import { useCardCategoryContext } from '../CardCategoryProvider';
import { GalleryTile } from '../GalleryTile';
import { PageSection } from '../PageSection';
import { useCardTelemetry, TelemetryProvider, enrichCardTelemetryExtraInfo } from '@apps/card/Card.telemetry';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useDesignTemplates } from './useDesignTemplates';
import { Box, Flex } from '@withjoy/joykit';
import { ConciergeServiceSection } from '../ConciergeServiceSection/ConciergeServiceSection';
import usePropValues from './usePropValues';
import { TravelBookingDialogCrossSell } from '../TravelBookingDialogCrossSell/TravelBookingDialogCrossSell';
import { useNativeState } from '@shared/core/native';
import { CustomizeNowClickOverrideHandler, StationeryTemplate } from './DesignsGallery.types';
import { useUrlSyncedFilters } from './useUrlSyncedFilters';
import { Filters } from './components/Filters/Filters';
import { CardEventType, GalleryItem } from './components/GalleryItem/GalleryItem';
import { pxToRem } from '@withjoy/joykit/theme';
import { CardDiscountProvider } from './useCardDiscountContext';
import { useConciergeDisclosure } from './useConciergeDisclosure';
import { useCardsRouterContext } from '@apps/card/Card.routes';

interface DesignsGalleryProps {
  disableTravelCrossSellDialog?: boolean;

  eventId?: string;
  eventHandle?: string;
  // Intended only to be used for the investor demo.
  onCustomizeCardClickOverride?: CustomizeNowClickOverrideHandler;
}

const Root = ({
  disableTravelCrossSellDialog,
  eventHandle,
  eventId,
  eventDesignThemeId,
  filterMatchCounts,
  onCustomizeCardClickOverride,
  templates
}: {
  templates: StationeryTemplate[];
  eventDesignThemeId: string | undefined;
  filterMatchCounts: Record<string, number>;
} & DesignsGalleryProps) => {
  useUrlSyncedFilters();
  useCardTelemetry({ page: 'homeRoute' });
  const { currentCategory } = useCardCategoryContext();
  const { isPublicShopRoute } = useCardsRouterContext();
  enrichCardTelemetryExtraInfo({ stationeryTemplateCategory: currentCategory });
  const { isNative } = useNativeState();

  const { value: isStDPaperlustConciergeEnabled } = useFeatureValue('printStDPaperlustConciergeEnabled');

  const isViewingCategoryWithConciergeService = [
    StationeryTemplateCategoryEnum.invitation,
    isStDPaperlustConciergeEnabled === 'on' ? StationeryTemplateCategoryEnum.saveTheDate : null
  ].includes(currentCategory);

  const isPaperlustConciergeServiceEnabled =
    useFeatureValue('printPaperlustConciergeServiceCarousel', {
      skip: !isViewingCategoryWithConciergeService || isPublicShopRoute
    }).value === 'on';

  const isPaperlustConciergeServiceGallery =
    useFeatureValue('printPaperlustShowConciergeServiceInGallery', {
      skip: !isViewingCategoryWithConciergeService || !isPaperlustConciergeServiceEnabled || isPublicShopRoute
    }).value === 'on';

  const templatesWithProps = usePropValues({ category: currentCategory, templates, conciergeEnabled: isPaperlustConciergeServiceGallery });

  const { t } = useTranslation('stationery');
  const translations = t('dashboard', 'designsGallery');
  const {
    isOpen: isConciergeModalOpen,
    onOpen: openConciergeModal,
    onClose: closeConciergeModal,
    selectedOffering: conciergeSelectedOffering,
    handleOnTileClick: conciergeHandleOnTileClick
  } = useConciergeDisclosure();

  const handleCardEvents = useCallback(
    (event: CardEventType) => {
      switch (event.type) {
        case 'conciergeselectedevent':
          conciergeHandleOnTileClick(event.id);
          break;
      }
    },
    [conciergeHandleOnTileClick]
  );

  return (
    <>
      {!disableTravelCrossSellDialog && !isNative && eventId && eventHandle && <TravelBookingDialogCrossSell eventId={eventId} eventHandle={eventHandle} />}
      {isPaperlustConciergeServiceEnabled && (
        <Box marginBottom={10}>
          <ConciergeServiceSection
            handleOnTileClick={conciergeHandleOnTileClick}
            isOpen={isConciergeModalOpen}
            onOpen={openConciergeModal}
            onClose={closeConciergeModal}
            selectedOffering={conciergeSelectedOffering}
            onCustomizeOfferingOverride={onCustomizeCardClickOverride}
            category={currentCategory}
            enableCarousel={!isPaperlustConciergeServiceGallery}
          />
        </Box>
      )}

      <PageSection
        id="designs-gallery"
        title={
          [StationeryTemplateCategoryEnum.invitation, StationeryTemplateCategoryEnum.saveTheDate].includes(currentCategory)
            ? translations.sectionTitle[currentCategory]?.()
            : () => <PageSection.Header title={translations.sectionTitle[currentCategory]?.()} description={translations.sectionDescription[currentCategory]?.()} />
        }
      >
        {templates && (
          <>
            {currentCategory !== StationeryTemplateCategoryEnum.thankYou && <Filters filterMatchCounts={filterMatchCounts} />}
            <Gallery>
              {templatesWithProps.map((data, index) => {
                return <GalleryItem onCardSelect={handleCardEvents} key={index} data={data} eventDesignThemeId={eventDesignThemeId} />;
              })}
            </Gallery>
          </>
        )}
      </PageSection>
    </>
  );
};

export const DesignsGallery = ({ disableTravelCrossSellDialog, eventId, eventHandle, onCustomizeCardClickOverride }: DesignsGalleryProps) => {
  const { filterMatchCounts, templates, eventDesignThemeId } = useDesignTemplates({ eventId });

  return (
    <SkeletonGroup isReady={!!templates.length} placeholder={<DesignsGallerySkeleton />}>
      <CardDiscountProvider>
        <TelemetryProvider>
          <Root
            disableTravelCrossSellDialog={disableTravelCrossSellDialog}
            filterMatchCounts={filterMatchCounts}
            templates={templates}
            eventDesignThemeId={eventDesignThemeId}
            eventId={eventId}
            eventHandle={eventHandle}
            onCustomizeCardClickOverride={onCustomizeCardClickOverride}
          />
        </TelemetryProvider>
      </CardDiscountProvider>
    </SkeletonGroup>
  );
};

const DesignsGallerySkeleton = () => {
  const { currentCategory } = useCardCategoryContext();

  const { isPublicShopRoute } = useCardsRouterContext();

  const { value: isStDPaperlustConciergeEnabled } = useFeatureValue('printStDPaperlustConciergeEnabled');

  const isViewingCategoryWithConciergeService = [
    StationeryTemplateCategoryEnum.invitation,
    isStDPaperlustConciergeEnabled === 'on' ? StationeryTemplateCategoryEnum.saveTheDate : null
  ].includes(currentCategory);

  const isPaperlustConciergeServiceEnabled =
    useFeatureValue('printPaperlustConciergeServiceCarousel', {
      skip: !isViewingCategoryWithConciergeService || isPublicShopRoute
    }).value === 'on';

  const isPaperlustConciergeServiceGallery =
    useFeatureValue('printPaperlustShowConciergeServiceInGallery', {
      skip: !isViewingCategoryWithConciergeService || !isPaperlustConciergeServiceEnabled
    }).value === 'on';

  return (
    <>
      {isPaperlustConciergeServiceEnabled && !isPaperlustConciergeServiceGallery && (
        <Box marginBottom={pxToRem(74)}>
          <ConciergeServiceSection.Skeleton category={currentCategory} />
        </Box>
      )}

      <PageSection
        id="design-gallery-skeleton"
        spacing={{ _: 7, md: 9 }}
        title={() =>
          isViewingCategoryWithConciergeService ? (
            <Box>
              <Skeleton width={270} height={7} />
            </Box>
          ) : (
            <Flex justifyContent="center" flexDirection="column" alignItems="center" rowGap={5}>
              <Skeleton width={340} height={36} />
              <Skeleton width={400} height={18} />
              <Skeleton width={280} height={18} />
            </Flex>
          )
        }
      >
        <Gallery>
          {Array.from({ length: 8 }).map((_, index) => (
            <GalleryTile.Skeleton key={index} />
          ))}
        </Gallery>
      </PageSection>
    </>
  );
};
