import { useMemo, useRef } from 'react';
import { StationeryTemplateCategoryEnum, useCardDashboardStationeryTemplatesByCategoryQuery, usePrintDesignsGalleryEventInfoQuery } from '@graphql/generated';

import { useManagedSearchParam } from '@apps/admin/common/hooks/useManagedSearchParam';
import { useCardCategoryContext } from '../CardCategoryProvider';
import { useFeatureValue } from '@shared/core/featureFlags';
import { ThemeJson } from '@apps/card/routes/CardCustomizer';
import { StationeryTemplate } from './DesignsGallery.types';
import { processInitialQueryData } from './utils';
import { useApplyFiltersToTemplates } from './useApplyFiltersToTemplates';

type UseDesignTemplatesArgs = {
  /**
   * Marking as optional to prepare for the future when card app is no longer tied to the admin view
   */
  eventId?: string;
};

type ThemeIdLookup = {
  [key: string]: {
    position: number;
    themeId: string;
  };
};

export type StationeryTemplateWithThemeJson = StationeryTemplate & { themeJSON: ThemeJson };
/*
 * This method allows setting order of top N items in the gallery based on array of themeIds coming from Amplitude Feature flag
 */
const setGalleryOrder = (templates: StationeryTemplate[], themeOrder: string[]): StationeryTemplate[] => {
  if (templates?.length < 1 || themeOrder?.length < 1) return templates;
  const orderedTemplates = [...templates];
  const themeLookup: ThemeIdLookup = {};
  themeOrder.forEach((themeId, index) => {
    themeLookup[themeId] = { themeId: themeId, position: themeOrder.length - index };
  });

  try {
    orderedTemplates.sort((a, b) => {
      const aIndex = themeLookup[a?.themeId]?.position ?? -1;
      const bIndex = themeLookup[b?.themeId]?.position ?? -1;
      return bIndex - aIndex;
    });
  } catch {
    console.error('Error sorting templates by printGalleryThemeDisplayOrder.  Using default sort order');
  }

  return orderedTemplates;
};

export const useDesignTemplates = (args: UseDesignTemplatesArgs) => {
  const { eventId } = args;
  const { currentCategory } = useCardCategoryContext();
  const [themeQueryParam] = useManagedSearchParam('theme');
  const themeQueryParamRef = useRef(themeQueryParam);
  const { value, payload } = useFeatureValue('printGalleryThemeDisplayOrder');

  const { applyFiltersToTemplates, deriveFilterCountFromTemplates } = useApplyFiltersToTemplates();

  const { data: eventInfoData } = usePrintDesignsGalleryEventInfoQuery({
    batchMode: 'fast',
    variables: {
      eventId: eventId || ''
    },
    fetchPolicy: 'cache-and-network',
    skip: !eventId
  });

  const eventDesignThemeId = eventInfoData?.eventById?.eventDesign?.theme.themeId;
  const { data } = useCardDashboardStationeryTemplatesByCategoryQuery({
    variables: {
      category: currentCategory,
      skipDigitalDesigns: currentCategory === StationeryTemplateCategoryEnum.thankYou
    },
    fetchPolicy: 'cache-first',
    batchMode: 'fast'
  });

  const templates = useMemo(() => {
    const mappedTemplates = processInitialQueryData(data, currentCategory);
    let sortedTemplates: StationeryTemplate[] = [...mappedTemplates];

    if (value === 'on' && Array.isArray(payload)) {
      sortedTemplates = setGalleryOrder(mappedTemplates, payload);
    }

    if (themeQueryParamRef.current || eventDesignThemeId) {
      // Sorting in place since we're already creating a new array above when we map the templates

      sortedTemplates.sort((a, b) => {
        // Prioritize the theme query param
        if (a.themeId === themeQueryParamRef.current) {
          return -1;
        } else if (b.themeId === themeQueryParamRef.current) {
          return 1;
        }

        // If no theme query param, prioritize the event design theme
        if (a.themeId === eventDesignThemeId) {
          return -1;
        } else if (b.themeId === eventDesignThemeId) {
          return 1;
        }

        return 0;
      });
    }

    return sortedTemplates;
  }, [data, currentCategory, eventDesignThemeId, value, payload]);

  return {
    filterMatchCounts: deriveFilterCountFromTemplates(templates),
    templates: applyFiltersToTemplates(templates),
    eventDesignThemeId
  };
};
