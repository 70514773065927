import React, { useEffect, useMemo } from 'react';
import EventCardV2 from '@apps/accountMe/components/EventCardV2';
import SectionTitleBar from '@apps/accountMe/components/SectionTitleBar';
import { useMeController } from './Me.controller';
import { EventCardsWrapper, MeWrapper, MeWrapperStyles, EventCardSectionStyles } from './Me.styles';
import { Box, ButtonV2 } from '@withjoy/joykit';
import { ReactComponent as SearchSVG } from '@assets/icons/search.svg';
import { useTranslation } from '@shared/core';
import ZeroEventsV2 from './components/ZeroEventsV2';
import { UserForMePageFragment } from '@graphql/generated';
import { LeaveEventDialogV2 } from '@apps/accountMe/components/Dialogs/LeaveEventV2';
import CreateEventButton from './components/CreateEventButton/CreateEventButton';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { ShareDialog } from '@apps/admin/routes/Dashboard/components/DashboardContentV2/ShareDialog/ShareDialog';
import { DeleteEventDialog } from '@apps/accountMe/components/Dialogs/DeleteEvent';
import { DeleteEventSuccessDialog } from '@apps/accountMe/components/Dialogs/DeleteEventSuccess';
import { useManagedSearchParam } from '@apps/admin/common/hooks/useManagedSearchParam';
import { EventCardSkeletonGroup } from './components/EventCardSkeletonGroup';
interface Props
  extends Readonly<{
    user?: UserForMePageFragment | null;
    loading: boolean;
  }> {}

enum MeComponentState {
  LOADING = 'loading',
  ZERO_EVENTS = 'zeroEvents',
  ONE_OR_MORE_EVENTS = 'oneOrMoreEvents'
}

export const Me: React.FC<Props> = props => {
  const { user, loading } = props;
  const isMobile = useIsMobileScreen();
  const {
    pastEventCards,
    upcomingEventCards,
    findEventUrl,
    handleMePageClick,
    eventIdToDeleteV2,
    eventIdToLeave,
    eventNameToLeave,
    handleCloseDeleteDialogV2,
    handleCloseLeaveDialog,
    onDeleteEventSuccessDialogClose,
    isEventDeleteSuccessDialogOpen,
    onEventDeleteSuccessDialogOpen,
    isShareDialogOpen,
    onShareDialogClose,
    guestSiteLink,
    eventPassword,
    eventHandle,
    isGuest
  } = useMeController({ user });

  const [eventDeleteSuccessValue, setEventDeleteSuccessValue] = useManagedSearchParam('eventDeleteSuccess');

  const { t2 } = useTranslation('account');
  const { myEventsTitle, findEventButton, pastEventsTitle } = t2('eventsPage');

  const shouldHideFindEvent = isMobile || !Boolean(upcomingEventCards.length);

  const renderFloatedCreateButton = isMobile && Boolean(upcomingEventCards.length);

  const componentState = useMemo<MeComponentState>(() => {
    if (loading) {
      return MeComponentState.LOADING;
    }

    if (upcomingEventCards.length === 0) {
      return MeComponentState.ZERO_EVENTS;
    }

    return MeComponentState.ONE_OR_MORE_EVENTS;
  }, [loading, upcomingEventCards.length]);

  useEffect(() => {
    if (eventDeleteSuccessValue) {
      onEventDeleteSuccessDialogOpen();
      setEventDeleteSuccessValue(null);
    }
  }, [eventDeleteSuccessValue, setEventDeleteSuccessValue, onEventDeleteSuccessDialogOpen]);

  return (
    <MeWrapper {...MeWrapperStyles}>
      <Box {...EventCardSectionStyles}>
        {componentState === 'oneOrMoreEvents' && (
          <SectionTitleBar title={myEventsTitle}>
            {!shouldHideFindEvent && (
              <ButtonV2
                as="a"
                href={findEventUrl}
                onClick={() => handleMePageClick('FindAnEvent', 'Events')}
                target="_blank"
                variant="outline"
                intent="neutral"
                startIcon={() => <SearchSVG />}
              >
                {findEventButton.value}
              </ButtonV2>
            )}
            <CreateEventButton renderFloating={renderFloatedCreateButton} />
          </SectionTitleBar>
        )}
        {componentState === 'zeroEvents' && <ZeroEventsV2 hideImage={Boolean(pastEventCards.length)} />}
        {componentState === 'loading' && (
          <>
            <SectionTitleBar title={myEventsTitle}>
              {!shouldHideFindEvent && (
                <ButtonV2
                  as="a"
                  href={findEventUrl}
                  onClick={() => handleMePageClick('FindAnEvent', 'Events')}
                  target="_blank"
                  variant="outline"
                  intent="neutral"
                  startIcon={() => <SearchSVG />}
                >
                  {findEventButton.value}
                </ButtonV2>
              )}
              {!loading && <CreateEventButton renderFloating={renderFloatedCreateButton} />}
            </SectionTitleBar>
            <EventCardsWrapper>
              <EventCardSkeletonGroup loading={loading} />
            </EventCardsWrapper>
            <SectionTitleBar title={pastEventsTitle}></SectionTitleBar>
            <EventCardsWrapper>
              <EventCardSkeletonGroup loading={loading} />
            </EventCardsWrapper>
          </>
        )}

        {componentState === 'oneOrMoreEvents' ? (
          <EventCardsWrapper>
            {upcomingEventCards.map(card => {
              return (
                <React.Fragment key={card.id}>
                  <EventCardV2 eventCard={card} key={card.id} />
                </React.Fragment>
              );
            })}
          </EventCardsWrapper>
        ) : null}
      </Box>
      {pastEventCards.length ? (
        <Box {...EventCardSectionStyles}>
          <SectionTitleBar title={pastEventsTitle}></SectionTitleBar>
          <EventCardsWrapper>
            {pastEventCards.map(card => {
              return <EventCardV2 eventCard={card} key={card.id} />;
            })}
          </EventCardsWrapper>
        </Box>
      ) : null}
      <ShareDialog
        isOpen={isShareDialogOpen}
        onClose={onShareDialogClose}
        isGuest={isGuest}
        guestSiteLink={guestSiteLink}
        eventPassword={eventPassword}
        eventHandle={eventHandle}
      />
      <LeaveEventDialogV2 isOpen={!!eventIdToLeave} eventId={eventIdToLeave} eventName={eventNameToLeave} onClose={handleCloseLeaveDialog} />
      <DeleteEventDialog isOpen={!!eventIdToDeleteV2} eventId={eventIdToDeleteV2} onClose={handleCloseDeleteDialogV2} />
      <DeleteEventSuccessDialog eventId={eventIdToDeleteV2} isOpen={isEventDeleteSuccessDialogOpen} onClose={onDeleteEventSuccessDialogClose} />
    </MeWrapper>
  );
};
