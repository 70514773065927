import { StationeryTemplateCategoryEnum, useCardPdpDataQuery } from '@graphql/generated';
import { cloneDeep } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { StationeryTemplateWithThemeJson } from '../DesignsGallery/useDesignTemplates';
import { findMatchingMarketingData, resetCardBackAndEnvelope } from '../DesignsGallery/utils';
import { ValidDigitalDesignApiData } from '../DesignsGallery/DesignsGallery.types';
import { SAVE_THE_DATE_PHOTO_THEMES } from '@apps/saveTheDate/constants';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { useCardTelemetry } from '@apps/card/Card.telemetry';

export type DigitalDesignTemplate = ValidDigitalDesignApiData;

export const usePdpData = (themeId: string, category: StationeryTemplateCategoryEnum, enableRedirectIfInvalid: boolean = false) => {
  const { redirectToCategoryShopAs404 } = useCardTelemetry();
  const { goToShop } = useCardsRouterContext();
  const { data, loading } = useCardPdpDataQuery({
    batchMode: 'fast',
    variables: {
      themeId,
      category,
      skipDigitalDesign: category === StationeryTemplateCategoryEnum.thankYou
    }
  });

  const template = useMemo(() => {
    const stationeryTemplate = data?.stationeryTemplatesByThemeId?.[0];

    if (stationeryTemplate) {
      const revisedTemplate = cloneDeep(stationeryTemplate) as StationeryTemplateWithThemeJson;
      resetCardBackAndEnvelope(revisedTemplate.themeJSON);
      return revisedTemplate;
    }
    return null;
  }, [data]);

  const digitalTemplate: DigitalDesignTemplate | null = useMemo(() => {
    if (data?.ecardDesign) {
      const ecardDesign = data.ecardDesign;
      const maybeMatchingMarketingData = findMatchingMarketingData(ecardDesign, category);
      if (maybeMatchingMarketingData) {
        return {
          themeId: ecardDesign.themeId,
          name: ecardDesign.name,
          thumbnailUrl: maybeMatchingMarketingData.imageUrl,
          category: category as Exclude<StationeryTemplateCategoryEnum, 'thankYou' | 'holiday'>
        };
      }
    }

    if (category === StationeryTemplateCategoryEnum.saveTheDate) {
      // TODO: photo ecards should be sent from card_service
      // https://withjoy.atlassian.net/browse/PRNT-1107
      const saveTheDateTheme = SAVE_THE_DATE_PHOTO_THEMES.find(theme => theme.themeId === themeId);
      if (saveTheDateTheme) {
        return {
          themeId: saveTheDateTheme.themeId,
          name: saveTheDateTheme.name,
          thumbnailUrl: saveTheDateTheme.thumbnailUrl,
          category
        };
      }
    }
    return null;
  }, [data, category, themeId]);

  const hasTemplate = !!(template || digitalTemplate);
  const shouldRedirectBackToCategoryShop = enableRedirectIfInvalid && !hasTemplate && !loading;

  useEffect(() => {
    if (shouldRedirectBackToCategoryShop) {
      redirectToCategoryShopAs404({ page: 'pdp', themeId });
      goToShop('wedding', category);
    }
  }, [category, goToShop, redirectToCategoryShopAs404, themeId, shouldRedirectBackToCategoryShop]);

  return {
    loading,
    template,
    digitalTemplate
  };
};
