import { ButtonV1, styled } from '@withjoy/joykit';

export const Button3rdPartyProvider = styled(ButtonV1)`
  svg {
    margin-right: 1rem;
  }
  span span {
    display: flex;
    align-items: center;
  }
`;
