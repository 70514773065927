import React from 'react';
import { StationeryDraftFormat, useGetStationaryPrintOrderCardDetailsByOrderNumberQuery } from '@graphql/generated';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import { Box, Flex, IconButton } from '@withjoy/joykit';
import { NonEditableCardPagePreview, ThemeJson } from '../CardCustomizer';
import { IRevealType, Reveal } from '@apps/greetingCard/components/Reveal/Reveal';
import { useWindowSize } from '@apps/greetingCard/components/Reveal/hooks';
import { InteractiveCard } from '../Dashboard/components/GalleryTile/InteractiveCard';
import { ReactComponent as JoyLogo } from '@assets/joy-logo-updated.svg';
import { QRCodeLayerData, TextLayerData } from '../CardCustomizer/components/Layer.types';
import { Link } from '@shared/core';
import { useCardsRouterContext } from '@apps/card/Card.routes';

interface RecipientPremiumEcardPreviewProps {
  orderNumber: string;
}

const DEFAULT_OPEN_DELAY = 4000;
const PADDING = 40;
const INNER_PADDING = 10;
// Larger number will make card larger in display.
// Most displays have much higher pixel density than 72.
// if we want to be closer to real size onscreen we need to use a higher number.
const DISPLAY_PPI = 120;

const getCardDimensions = (card: ThemeJson['card']) => {
  let dimensions = card?.layout;
  if (!dimensions && card?.front?.dimensions) {
    dimensions = card.front.dimensions;
  }
  return dimensions;
};

const Footer = ({}: {}) => {
  const { getPublicRootPath } = useCardsRouterContext();
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      gap={6}
      margin={0}
      bottom={0}
      left={0}
      right={0}
      paddingX={{ _: 6, sm2: 10 }}
      paddingY={6}
      position="fixed"
      backgroundColor="mono1"
      zIndex={500}
    >
      <IconButton size="sm" aria-label="Back" variant="ghost" as={'a'} href="https://withjoy.com" target="_blank" height={{ _: 6, sm2: 7 }}>
        <JoyLogo height="100%" />
      </IconButton>
      <Flex flexDirection="row" alignItems="center" gap={6}>
        <Box as="a" href="https://withjoy.com/help/en/?utm_source=e-card-invite&utm_medium=viral&utm_campaign=guest-help" target="_blank" typographyVariant="label2" color="mono12">
          Guest Help
        </Box>
        <Box as="a" href="https://withjoy.com/" target="_blank" typographyVariant="label2" color="mono12">
          About
        </Box>
        <Box as={Link} to={getPublicRootPath()} target="_blank" typographyVariant="label2" color="mono12">
          Browse Joy Cards
        </Box>
      </Flex>
    </Flex>
  );
};

export const RecipientPremiumEcardPreviewContent = (props: { cardJson: ThemeJson }) => {
  const { width: winWidth } = useWindowSize();
  const { cardJson } = props;

  const cardFront = cardJson?.card?.front;
  const cardBack = cardJson?.card?.back;
  const shape = cardJson?.customizations?.shape;
  const qrLayer: QRCodeLayerData = cardJson?.card?.back?.layers?.find?.(layer => layer.type === 'qrcode') as QRCodeLayerData;
  const linkLayer: TextLayerData = cardJson?.card?.back?.layers?.find?.(layer => layer.name?.toLowerCase() === 'url') as TextLayerData;
  let cardBackLink;
  if (qrLayer) {
    cardBackLink = qrLayer?.qrcodeData?.link;
  } else if (linkLayer) {
    cardBackLink = linkLayer?.textData?.content;
  }

  const dimensions = getCardDimensions(cardJson?.card);
  const width = dimensions?.width ?? 0;
  const height = dimensions?.height ?? 0;
  const MAX_HEIGHT = height * DISPLAY_PPI;
  const MAX_WIDTH = width * DISPLAY_PPI;

  // TODO: get background color from theme and remove fallback
  const availbleHeight = window.innerHeight - 80;
  const backgroundColor = cardJson.digitalCustomizations?.backgroundColor ?? '#f9fafb';
  const divisor = availbleHeight / MAX_HEIGHT;
  const WIDTH = availbleHeight > MAX_HEIGHT ? MAX_WIDTH : MAX_WIDTH * divisor;
  let revealWidth = WIDTH;

  if (winWidth <= WIDTH + PADDING) {
    revealWidth = winWidth - PADDING;
  }

  const contentHeight = Math.floor(revealWidth * (height / width)) - INNER_PADDING * 2;
  const contentWidth = revealWidth - INNER_PADDING;

  return (
    <Box backgroundColor={backgroundColor}>
      <Box margin={0} padding={0} position="absolute" top={0} left={0} right={0} bottom={0} backgroundColor={backgroundColor}>
        <Reveal
          preventToggleOnContentClick
          width={revealWidth}
          themeColor="#ffffff"
          type={IRevealType.PremiumEnvelope}
          backgroundColor={backgroundColor}
          autoPlayDelay={DEFAULT_OPEN_DELAY}
          loading={false}
          bottomOffset={{ _: 120, sm2: 200 }}
        >
          <Flex flexDirection="column" marginX="auto" width={contentWidth} gap={6}>
            <InteractiveCard width={contentWidth} height={contentHeight} shape={shape} texture={'https://withjoy.com/media/raw/print/texture_fabric_dark.png'}>
              <NonEditableCardPagePreview format={StationeryDraftFormat.digital} page={cardFront} shape={shape} />
            </InteractiveCard>
            <InteractiveCard link={cardBackLink} width={contentWidth} height={contentHeight} shape={shape} texture={'https://withjoy.com/media/raw/print/texture_fabric_dark.png'}>
              <NonEditableCardPagePreview format={StationeryDraftFormat.digital} page={cardBack} shape={shape} />
            </InteractiveCard>
          </Flex>
        </Reveal>
      </Box>
    </Box>
  );
};

export const RecipientPremiumEcardPreview = (props: RecipientPremiumEcardPreviewProps) => {
  const { data, loading } = useGetStationaryPrintOrderCardDetailsByOrderNumberQuery({
    variables: {
      orderNumber: props.orderNumber
    },
    batchMode: 'fast'
  });

  if (loading) {
    return <JoyLogoLoader loaderKey="recipient-premium-ecard-preview" />;
  }

  const cardJson = data?.stationeryPrintOrderCardDetailsByOrderNumber?.detailsJSONBlob as ThemeJson;
  if (!cardJson) {
    return <JoyLogoLoader loaderKey="recipient-premium-ecard-preview" />;
  }

  return (
    <>
      <RecipientPremiumEcardPreviewContent cardJson={cardJson} />
      <Footer />
    </>
  );
};
