/**
 * A factory that creates a function that will invoke the provided callback function argument at most one time.
 *
 * @example
 * import { invokeFnOnceFactory } from ''
 *
 * const hydrateFiltersOnce = invokeFnOnceFactory();
 *
 * const useSomeHook = () => {
 *  hydrateFiltersOnce(() => {
 *    doSomethingAtMostOnce();
 *  });
 * }
 */
export const invokeFnOnceFactory = () => {
  let hasInvokedOnce = false;
  return (cb: Function) => {
    if (!hasInvokedOnce) {
      hasInvokedOnce = true;
      cb();
    }
  };
};
