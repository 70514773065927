import React from 'react';
import { Flex, TextV1 } from '@withjoy/joykit';
import { SubtleLink, StyledPrivacyNoticy } from '../CommonStyles';
import { useTranslation } from '@shared/core';

export const TermsAndPrivacyAgreement: React.FC<{}> = () => {
  const { t } = useTranslation('account');
  const termsAndCondition = t('termsandprivacy')({
    termsLink: () => (
      <SubtleLink href="https://withjoy.com/EULA" target="__blank">
        {t('terms')()}
      </SubtleLink>
    ),
    privacyLink: () => (
      <SubtleLink href="https://withjoy.com/privacy" target="__blank">
        {t('privacyPolicy')()}
      </SubtleLink>
    )
  });
  return (
    <Flex justifyContent="center">
      <TextV1 variant="label2">
        {termsAndCondition}
        <StyledPrivacyNoticy as="span" hidden={true} className="truevault-polaris-dns-link">
          {t('privacyNoticeSee')()}
          <SubtleLink href="https://privacy.withjoy.com/privacy-policy#california-privacy-notice" target="__blank">
            {t('privacyNotice')()}
          </SubtleLink>
          .
        </StyledPrivacyNoticy>
      </TextV1>
    </Flex>
  );
};
