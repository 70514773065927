import React from 'react';
import { Flex, TextV2 } from '@withjoy/joykit';
import { SkeletonText } from '@shared/components/Skeleton';
import { useGetDiscountedPrice } from '../../DesignsGallery/useCardDiscountContext';
import { CardTileDiscountedPriceLabel } from '../../DesignsGallery/components/CardTileDiscountedPriceLabel';

interface TitleBlockProps {
  title: string;
  subtitle?: string;
}

const Root: React.FC = ({ children }) => {
  return (
    <Flex flexDirection="column" rowGap={3} marginBottom={{ _: 5, sm2: 8 }}>
      {children}
    </Flex>
  );
};
export const TitleBlock = (props: TitleBlockProps) => {
  const { title, subtitle } = props;
  const getDiscountedPrice = useGetDiscountedPrice();
  const { currentSale, discountedPrice, currentCategory } = getDiscountedPrice();

  return (
    <Root>
      <TextV2 fontWeight={{ sm2: 600 }} typographyVariant={{ _: 'hed4', sm2: 'display1' }}>
        {title}
      </TextV2>
      {discountedPrice && <CardTileDiscountedPriceLabel sale={currentSale!} discountedPrice={discountedPrice} category={currentCategory} alignment="flex-start" />}
      {subtitle && (
        <TextV2 color="mono12" fontWeight={{ _: 500, sm2: 500 }} typographyVariant={{ _: 'hed1', sm2: 'hed4' }}>
          {subtitle}
        </TextV2>
      )}
    </Root>
  );
};

TitleBlock.Skeleton = () => {
  return (
    <Root>
      <SkeletonText rows={1} width={{ _: 160, sm2: 200 }} skeletonHeight={{ _: 24, sm2: 30 }} />
      <SkeletonText rows={1} width={80} skeletonHeight={{ _: 20, sm2: 24 }} />
    </Root>
  );
};
