import { Box, BoxProps } from '@withjoy/joykit';
import React from 'react';

interface GalleryProps {
  children: React.ReactNode;
  columns?: BoxProps['gridTemplateColumns'];
}

const DEFAULT_RESPONSIVE_COLUMNS: GalleryProps['columns'] = {
  _: 'repeat(2, 1fr)',
  sm2: 'repeat(3, 1fr)',
  md3: 'repeat(4, 1fr)',
  lg: 'repeat(5, 1fr)',
  xl: 'repeat(6, 1fr)'
};

export const Gallery = (props: GalleryProps) => {
  const { children, columns = DEFAULT_RESPONSIVE_COLUMNS } = props;
  return (
    <Box
      display="grid"
      maxWidth="100%"
      gridTemplateColumns={columns}
      alignItems="start"
      columnGap={3}
      rowGap={{
        _: 6,
        sm2: 8,
        md3: 10
      }}
      paddingBottom={8}
    >
      {children}
    </Box>
  );
};
