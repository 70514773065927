import React from 'react';
import { FormikProps } from 'formik';
import { Box, ButtonV2, CancelButton, TextV2, IconV2 } from '@withjoy/joykit';
import { StyledEcardEditorDrawer, DrawerButtonsWrapper, FormWrapper } from './EcardEditPanel.styles';
import { useEcardEditorController } from './EcardEditPanel.controller';
import { EcardDesignInputFields } from '@shared/components/EmailsAndEcards/components/Design/Design.controller';
import { EcardEditorType, EcardEventDesign } from '@apps/ecard/Ecard.types';
import EcardDesignEditor from './components/EcardDesignEditor';
import EcardContentEditor from './components/EcardContentEditor';
import EcardEditorSwitch from './components/EcardEditorSwitch';
import { ElectronicCardCardLinkOptionFragment } from '@graphql/generated';
import { darkButtonOverride } from '@apps/postOffice/components/Shared.styles';
import { DesignEmail } from '@withjoy/joykit/icons';

export interface EcardEditorProps
  extends Readonly<{
    show: boolean;
    formik: FormikProps<EcardDesignInputFields>;
    toggleEcardEditor: () => void;
    handleCancelClick: () => void;
    selectedEditorType: EcardEditorType;
    handleSetSelectedEditorType: (type: EcardEditorType) => void;
    eventDesign?: EcardEventDesign | null;
    eventHandle: string;
    eventDisplayName: string;
    handleScrollToFront: () => void;
    handleScrollToBack: () => void;
    linkOptions?: ElectronicCardCardLinkOptionFragment[];
  }> {}

export const EcardEditPanel: React.FC<EcardEditorProps> = ({
  show,
  formik,
  toggleEcardEditor,
  handleCancelClick,
  selectedEditorType,
  handleSetSelectedEditorType,
  eventDesign,
  eventHandle,
  eventDisplayName,
  handleScrollToFront,
  handleScrollToBack,
  linkOptions
}) => {
  const { tEcardEditPanel, onSaveClick } = useEcardEditorController({
    formik,
    toggleEcardEditor
  });

  return (
    <StyledEcardEditorDrawer isOpen={show} position="right" canCloseOnOutsideClick={true} useBackdrop={false} onClose={handleCancelClick}>
      <Box style={{ position: 'relative', height: '100%' }}>
        <FormWrapper padding={8}>
          <TextV2 typographyVariant="hed6" fontWeight={700} marginBottom={8}>
            <IconV2 size="md" marginRight="12px" display={['block', 'block', 'none !important']}>
              <DesignEmail />
            </IconV2>{' '}
            {tEcardEditPanel.header}
          </TextV2>
          <EcardEditorSwitch selectedEditorType={selectedEditorType} handleSetSelectedEditorType={handleSetSelectedEditorType} />
          {selectedEditorType === EcardEditorType.DESIGN ? (
            <EcardDesignEditor formik={formik} eventDesign={eventDesign} />
          ) : (
            <EcardContentEditor
              formik={formik}
              eventHandle={eventHandle}
              linkOptions={linkOptions}
              eventDisplayName={eventDisplayName}
              handleScrollToFront={handleScrollToFront}
              handleScrollToBack={handleScrollToBack}
            />
          )}
        </FormWrapper>
        <DrawerButtonsWrapper alignItems="center" justifyContent="center" flexDirection="column" padding="24px">
          <ButtonV2 overrides={darkButtonOverride} variant="solid" intent="primary" onClick={onSaveClick} disabled={!formik.isValid} width="100%" marginBottom="16px">
            {tEcardEditPanel.doneButtonText}
          </ButtonV2>
          <CancelButton onClick={handleCancelClick}>{tEcardEditPanel.cancelButtonText}</CancelButton>
        </DrawerButtonsWrapper>
      </Box>
    </StyledEcardEditorDrawer>
  );
};
