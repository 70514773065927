import React from 'react';
import { useSignUpController } from './SignUp.controller';
import { SignInContent } from '../CommonStyles';
import { InputV1, FormField, LinkV1, Flex, ButtonV2 } from '@withjoy/joykit';
import { ProviderSettings } from '@apps/account/routes/SignIn/SignInPage.controller';
import { SignInUp3rdPartyProviders } from '../SignInUp3rdPartyProviders';
import { TermsAndPrivacyAgreement } from '../TermsAndPrivacyAgreement';
import { useTranslation } from '@shared/core';
import { AlternateModeText, Divider, StyledForm, PrivacyWrapper } from './SignUp.styles';

interface Props
  extends Readonly<{
    providers?: ProviderSettings;
    redirectUri?: string;
    signInInstead: () => void;
  }> {}

export const SignUp: React.FC<Props> = props => {
  const { providers = {}, signInInstead, redirectUri } = props;
  const { formik, thirdPartyClickHandler, loading, submitError } = useSignUpController(redirectUri);
  const { t2, t } = useTranslation('account');
  const tSignIn = t2('signin');
  const tSignUp = t('signup');
  const signInFragment = t('alreadyAccount')({
    signInLink: () => (
      <LinkV1 style={{ marginLeft: '0.5rem' }} color={'linkActive'} onClick={signInInstead}>
        {tSignIn.signin}
      </LinkV1>
    )
  });

  return (
    <SignInContent>
      {providers.google || providers.facebook ? <SignInUp3rdPartyProviders providers={providers} mode="signup" onThirdPartyClick={thirdPartyClickHandler} /> : null}
      {(providers.emailpassword && (
        <StyledForm onSubmit={formik.handleSubmit}>
          <FormField label="" error={formik.errors.email && formik.touched.email ? formik.errors.email : submitError ? submitError : undefined}>
            <InputV1 placeholder={tSignIn.enterEmail} autoComplete="username" {...formik.getFieldProps('email')} />
          </FormField>
          <FormField error={formik.errors.password && formik.touched.password ? formik.errors.password : undefined} label="">
            <InputV1 placeholder={tSignIn.enterPassword} autoComplete="new-password" {...formik.getFieldProps('password')} type={'password'} />
          </FormField>
          <Flex justifyContent="left">
            <div style={{ flexShrink: 0 }}>
              <ButtonV2 type="submit" intent="primary" disabled={loading}>
                {tSignUp.signup()}
              </ButtonV2>
            </div>
            <PrivacyWrapper>
              <TermsAndPrivacyAgreement />
            </PrivacyWrapper>
          </Flex>
        </StyledForm>
      )) ||
        null}
      <Divider />
      <AlternateModeText>{signInFragment}</AlternateModeText>
    </SignInContent>
  );
};
