import { PRICE_CONFIG } from '@apps/card/config';
import { Sale } from '@apps/card/routes/CardCustomizer/useSalesConfig';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';

type CardTileDiscountedPriceLabelProps = {
  discountedPrice?: string;
  sale: Sale;
  category: StationeryTemplateCategoryEnum;
  alignment?: 'flex-start' | 'center';
};
export const SalePurpleColor = '#6A36FF';

export const CardTileDiscountedPriceLabel = (props: CardTileDiscountedPriceLabelProps) => {
  const { discountedPrice, sale, category, alignment } = props;
  const cardPricingConfig = PRICE_CONFIG.quantity[category];

  return (
    <Flex flexDirection="column" gap="5px" justifyContent={alignment ?? 'center'} alignItems={alignment ?? 'center'}>
      <Flex flexDirection="row" gap="4px" justifyContent="center" alignItems="center">
        <TextV2 typographyVariant="label2">From </TextV2>
        <TextV2 as="span" textDecoration="line-through" typographyVariant="label2">
          {cardPricingConfig[100].individualPriceString}
        </TextV2>
        <TextV2 as="span" color={SalePurpleColor} textAlign="center" typographyVariant="hed4">
          {discountedPrice}
        </TextV2>
      </Flex>
      <TextV2 as="span" color={SalePurpleColor} typographyVariant="hed2" fontSize="14px">
        Use code:{' '}
        <TextV2
          as="a"
          color={SalePurpleColor}
          typographyVariant="hed2"
          fontSize="14px"
          textDecoration="none"
          _hover={{ textDecoration: 'underline' }}
          href="https://withjoy.com/help/en/articles/9312890-paper-and-invitations-promotions-and-discounts?srsltid=AfmBOoqodWdx4sZJJ0623p5wzZNRUo7nLcnz3emL1A543KiIZeT4kD5v"
        >
          {sale?.code}
        </TextV2>
      </TextV2>
    </Flex>
  );
};
