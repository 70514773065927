import marketingFonts from '@shared/utils/fonts/marketingFonts';
import { styled } from '@withjoy/joykit';

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${props => props.theme.colors.mono3};
`;

export const AlternateModeText = styled.div`
  ${marketingFonts.FeatureCopy17}
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.mono6};
`;

export const StyledForm = styled.form`
  padding: 32px 24px;
  & > * {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const PrivacyWrapper = styled.div`
  display: flex;
  margin-left: 25px;
`;
