import React from 'react';
import { Box, Flex, PopoverV2, useDisclosure } from '@withjoy/joykit';
import { ChevronDown, ChevronUp } from '@withjoy/joykit/icons';

import { animationTransition } from '@shared/utils/animationTransition';
import { ListCheckboxRow } from './ListCheckboxRow';
import { activePrintFiltersCacheApi } from '../../../utils/activePrintFiltersCache';
import { FilterFacet } from '../Filter.types';

interface FilterPillProps {
  facet: FilterFacet;
  onFilterChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const FilterPill = React.memo((props: FilterPillProps) => {
  const { facet, onFilterChange } = props;
  const { label, options } = facet;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const activeFiltersCount = activePrintFiltersCacheApi.getActiveFilterCountByGroupCode(facet.groupCode);

  const ArrowIcon = isOpen ? ChevronUp : ChevronDown;
  return (
    <PopoverV2 isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom">
      <PopoverV2.Trigger>
        <Box
          backgroundColor={isOpen ? 'mono3' : 'mono2'}
          color="mono14"
          as="button"
          height={40}
          paddingX={5}
          borderRadius={24}
          transition={animationTransition('background-color')}
        >
          <Box as="span" display="inline-flex" borderRadius="inherit" alignItems="center" columnGap={3} typographyVariant="button1">
            {activeFiltersCount > 0 && (
              <Flex borderRadius="inherit" backgroundColor="black" color="white" size={30} justifyContent="center" alignItems="center">
                {activeFiltersCount}
              </Flex>
            )}
            {label}
            <ArrowIcon size={16} />
          </Box>
        </Box>
      </PopoverV2.Trigger>
      <PopoverV2.Content>
        <Flex flexDirection="column" paddingX={5} paddingY={4} rowGap={2}>
          {options.map(option => (
            <ListCheckboxRow key={option.compositeCode} {...option} onChange={onFilterChange} />
          ))}
        </Flex>
      </PopoverV2.Content>
    </PopoverV2>
  );
});
