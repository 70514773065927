import { Accordion, Box, ButtonV2, Flex, FormControl, InputV2, TextV2 } from '@withjoy/joykit';
import React, { useState } from 'react';
import { useCustomizationContext } from '../../useCardCustomization';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { Trash } from '@withjoy/joykit/icons';
import { useTranslation } from '@shared/core';
import { isPromoValid, PromotionConfig, usePromotionConfig } from '../../usePromotionConfig';
import { useCardCustomizerTelemetry } from '../../CardCustomizer.telemetry';

interface RootProps {
  promotions: Array<PromotionConfig>;
  currentPromotion: PromotionConfig | undefined;
}

const Root = (props: RootProps) => {
  const { promotions, currentPromotion } = props;
  const [customizations, setCustomizations] = useCustomizationContext(draft => draft.config.customizations);
  const [category] = useCustomizationContext(draft => draft.stationeryTemplateCategory, true);
  const appliedPromoCode = customizations.promoCode;
  const { promoCodeInputButtonInteracted } = useCardCustomizerTelemetry();

  const [{ promoCode, error }, setState] = useState<{ promoCode: string; error?: string }>({ promoCode: appliedPromoCode || '', error: undefined });

  const { t } = useTranslation('stationery');
  const promoBlockTrans = t('cardCustomizer', 'promoCodeBlock');

  const handleOnApplyClick = useEventCallback(() => {
    const promoConfig = promotions.find(p => p.code.toLowerCase() === promoCode.toLowerCase());

    if (!promoConfig || !isPromoValid(promoConfig, { occasion: 'wedding', category })) {
      promoCodeInputButtonInteracted({ element: 'applyCta', promoCode, promotion: promoConfig, isValid: false });
      setState(prev => ({ ...prev, error: promoBlockTrans.invalidPromoCode() }));
      return;
    }

    promoCodeInputButtonInteracted({ element: 'applyCta', promoCode, promotion: promoConfig, isValid: true });

    setCustomizations(draft => {
      draft.promoCode = promoConfig.code;
    });
  });

  const handleOnRemoveClick = useEventCallback(() => {
    promoCodeInputButtonInteracted({ element: 'removeCta', promoCode, promotion: currentPromotion });
    setState(prev => ({ promoCode: '', error: undefined }));
    setCustomizations(draft => (draft.promoCode = undefined));
  });

  return (
    <Box>
      <Accordion>
        <Accordion.Item>
          <Accordion.Button padding="0" _hover={{ backgroundColor: 'white' }} _active={{ backgroundColor: 'white' }} justifyContent="space-between">
            <TextV2 typographyVariant="hed3">{promoBlockTrans.title()}</TextV2>
            {!appliedPromoCode && <Accordion.Icon />}
          </Accordion.Button>

          {appliedPromoCode ? (
            <Flex justifyContent="space-between" columnGap={6} alignItems="center" paddingTop={5}>
              <TextV2 typographyVariant="button1">{appliedPromoCode}</TextV2>

              <ButtonV2 startIcon={() => <Trash marginRight={-1} size={11} />} variant="link" size="sm" intent="neutral" onClick={handleOnRemoveClick}>
                {promoBlockTrans.removeCta()}
              </ButtonV2>
            </Flex>
          ) : (
            <Accordion.Panel paddingX={0} paddingBottom={0} paddingTop={5}>
              <FormControl isInvalid={!!error} error={error}>
                <Flex
                  as="form"
                  onSubmit={e => {
                    e.preventDefault();
                    handleOnApplyClick();
                  }}
                  columnGap={3}
                >
                  <InputV2
                    placeholder={promoBlockTrans.inputPlaceholder()}
                    value={promoCode}
                    onChange={e => {
                      setState(prev => ({ error: undefined, promoCode: e.target.value }));
                    }}
                  />
                  <ButtonV2 variant="outline" intent="neutral" type="submit">
                    {promoBlockTrans.applyCta()}
                  </ButtonV2>
                </Flex>
              </FormControl>
            </Accordion.Panel>
          )}
        </Accordion.Item>
      </Accordion>
    </Box>
  );
};

export const PromoCodeInput = () => {
  const { isEnabled, promotions, currentPromotion } = usePromotionConfig();

  if (!isEnabled || !promotions) {
    return null;
  }

  return <Root promotions={promotions} currentPromotion={currentPromotion} />;
};
