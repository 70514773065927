import React from 'react';
import { StationeryTemplate } from '../../../DesignsGallery.types';
import { GalleryTile, TagVariant } from '../../../../GalleryTile';
import { templateToTitle } from '@apps/card/Card.utils';
import { maybeApplyCallback } from '@shared/utils/maybeApplyCallback';
import { Box } from '@withjoy/joykit';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { PRICE_CONFIG } from '@apps/card/config';
import { useTranslation } from '@shared/core';
import { useAuth } from '@shared/components/AuthProvider';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useCardDiscountContext } from '../../../useCardDiscountContext';
import { isPromoValidForVendor } from '@apps/card/routes/CardCustomizer/usePromotionConfig';
import { CardTileDiscountedPriceLabel } from '../../CardTileDiscountedPriceLabel';

type CardTileProps = {
  data: StationeryTemplate & { type: 'template' };
  eventDesignThemeId?: string;
};

export const CardTile = (props: CardTileProps) => {
  const { currentUser } = useAuth();
  const isSuperAdmin = currentUser && currentUser.profile?.superAdmin;
  const { t } = useTranslation('stationery');
  const { value: printEnableDigitalEditorEnabled } = useFeatureValue('printEnableDigitalEditor');

  const { discountedPrice, discountEnabled, sale } = useCardDiscountContext()!;

  const translations = t('dashboard', 'designsGallery');
  const { data, eventDesignThemeId } = props;
  const { category, approved, format, thumbnailUrl, enabled, digitalDesignThumbnailUrl } = data;
  const cardPricingConfig = PRICE_CONFIG.quantity[category];

  const { designGalleryTileClicked } = useCardTelemetry();

  const { goToProductDetailsPage } = useCardsRouterContext();

  const handleOnViewTemplatePdpClick = useEventCallback((template: StationeryTemplate) => {
    designGalleryTileClicked({ themeId: template.themeId, templateCategory: template.category, format: template.format });
    goToProductDetailsPage('wedding', category, template.themeId);
  });
  const paperOnlyTag = printEnableDigitalEditorEnabled === 'on' ? 'printAndPremiumDigitalAvailable' : 'printAvailable';

  const tagVariant: TagVariant = enabled
    ? approved
      ? // Based on format
        format === 'digital'
        ? 'digitalOnly'
        : format === 'paper'
        ? paperOnlyTag
        : format === 'paperAndDigital'
        ? 'printAndDigitalAvailable'
        : paperOnlyTag
      : 'comingSoon'
    : 'disabled';

  const isPremium = printEnableDigitalEditorEnabled === 'on' && (data.premium || format === 'paper');

  const isSaleValid = discountEnabled && sale && isPromoValidForVendor(sale, 'taylor');
  const priceElement =
    tagVariant === 'digitalOnly' ? (
      'FREE'
    ) : cardPricingConfig?.[100] ? (
      isSaleValid ? (
        <CardTileDiscountedPriceLabel category={category} discountedPrice={discountedPrice} sale={sale} />
      ) : (
        `From ${cardPricingConfig[100].individualPriceString}`
      )
    ) : (
      ''
    );

  return enabled || isSuperAdmin ? (
    <GalleryTile onClick={maybeApplyCallback(!!(approved || isSuperAdmin), () => handleOnViewTemplatePdpClick(data))}>
      {eventDesignThemeId === data.themeId && (
        <Box
          typographyVariant="label1"
          fontWeight={600}
          position="absolute"
          paddingX={4}
          paddingY={2}
          right={5}
          top={4}
          backgroundColor="mono14"
          color="white"
          userSelect="none"
          borderRadius={5}
          zIndex={1}
        >
          {translations.websiteMatch()}
        </Box>
      )}
      <GalleryTile.AssetPreview assetUrl={(thumbnailUrl || digitalDesignThumbnailUrl) ?? ''}>{isPremium && <GalleryTile.PremiumBadge />}</GalleryTile.AssetPreview>
      <GalleryTile.Content title={templateToTitle(data)} label={priceElement}>
        <GalleryTile.Tag variant={tagVariant} />
      </GalleryTile.Content>
    </GalleryTile>
  ) : null;
};
