import React from 'react';
import { LinkV2 } from '@withjoy/joykit';
import { InlineMessage } from '@apps/card/components/PrintJobDetails/components/InlineMessage';
import { useTranslation, useRouterHelper } from '@shared/core';
import { useFeatureFlagsContext } from '@shared/core/featureFlags';
import { StationeryTemplateIdentifiers } from '../../types';
import { ecardRoutePaths } from '@apps/ecard/Ecard.route.paths';
import { postOfficeRoutePaths } from '@apps/postOffice/PostOffice.route.paths';
import { StationeryTemplateCategoryEnum, EcardType, PostOfficeMessageType } from '@graphql/generated';
import { getThemeInfoByThemeId } from '@apps/saveTheDate/SaveTheDate.utils';
import { useCardTelemetry } from '@apps/card/Card.telemetry';

interface OnlyUSShippingWarningMessageProps {
  templateIdentifiers: StationeryTemplateIdentifiers | null;
  telemetryDialogId: 'restrictedInternationalShippingWarningDialog' | 'shippingAddressDialog' | 'checkoutErrorDialog';
  noMobileXMargin?: boolean;
}

const STATIONERY_TEMPLATE_TO_ECARD_TYPE_OR_EMAIL_TYPE: Record<StationeryTemplateCategoryEnum, EcardType | PostOfficeMessageType | undefined> = {
  saveTheDate: EcardType.savethedate,
  invitation: EcardType.invitation,
  thankYou: PostOfficeMessageType.thankyou,
  holiday: undefined
};

export const OnlyUSShippingWarningMessage: React.FC<OnlyUSShippingWarningMessageProps> = props => {
  const { templateIdentifiers, telemetryDialogId, noMobileXMargin = false } = props;
  const { buildPath } = useRouterHelper();
  const { t } = useTranslation('stationery');
  const translations = t('dashboard', 'restrictedInternationalShippingWarningDialog');
  const { formatToUrl } = useFeatureFlagsContext();
  const { restrictedInternationalShippingWarningDialogCreateEcardClicked } = useCardTelemetry();

  const buildLinkURL = () => {
    let url: URL;

    if (templateIdentifiers?.category && templateIdentifiers.themeId) {
      const type = STATIONERY_TEMPLATE_TO_ECARD_TYPE_OR_EMAIL_TYPE[templateIdentifiers.category];
      if (type) {
        if (templateIdentifiers.category === StationeryTemplateCategoryEnum.thankYou) {
          url = new URL(window.location.origin + buildPath(`email/${postOfficeRoutePaths.create.path}`) + `?draftType=${type}`);
        } else {
          url = new URL(
            window.location.origin +
              buildPath(
                ecardRoutePaths.design.goToPath(
                  `${templateIdentifiers.category === StationeryTemplateCategoryEnum.saveTheDate && !!getThemeInfoByThemeId(templateIdentifiers.themeId) ? '' : 'ecard'}/${type}`
                )
              )
          );
          url.searchParams.set('theme', templateIdentifiers.themeId);
          url.searchParams.set('save', 'true');
        }
      } else {
        url = new URL(window.location.origin + buildPath(ecardRoutePaths.design.goToPath(`ecard/${STATIONERY_TEMPLATE_TO_ECARD_TYPE_OR_EMAIL_TYPE.saveTheDate}`)));
      }
    } else {
      url = new URL(window.location.origin + buildPath(ecardRoutePaths.design.goToPath(`ecard/${STATIONERY_TEMPLATE_TO_ECARD_TYPE_OR_EMAIL_TYPE.saveTheDate}`)));
    }

    return formatToUrl(url.toString());
  };

  const handleOnClick = () => {
    templateIdentifiers?.themeId && restrictedInternationalShippingWarningDialogCreateEcardClicked({ themeId: templateIdentifiers.themeId, dialogId: telemetryDialogId });
  };

  const isThankYou = templateIdentifiers?.category === StationeryTemplateCategoryEnum.thankYou;

  return (
    <InlineMessage
      icon="warning"
      message={
        <>
          {isThankYou ? translations.warningMessageEmailText() : translations.warningMessageEcardText()}{' '}
          <LinkV2
            _activeLink={{ color: 'mono14' }}
            _visited={{ color: 'mono14' }}
            _active={{ color: 'mono14' }}
            _pressed={{ color: 'mono14' }}
            _hover={{ color: 'mono14', textDecoration: 'underline #D6D6D6' }}
            textDecoration="underline #333333"
            color="mono14"
            fontSize="15px"
            href={buildLinkURL()}
            isExternal
            onClick={handleOnClick}
          >
            {isThankYou ? translations.warningMessageLinkThankYou() : translations.warningMessageLink()}
          </LinkV2>
        </>
      }
      noMobileXMargin={noMobileXMargin}
    />
  );
};
