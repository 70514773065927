import React from 'react';
import { Box, Flex } from '@withjoy/joykit';
import { PropAsset } from '../../../../usePropValues';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { BOX_SHADOW_8, BOX_SHADOW_60_02 } from '@apps/card/boxShadow';
import { animationTransition } from '@shared/utils/animationTransition';
import ContentCarousel from './components/Carousel';

type ValuePropCardProps = {
  data: PropAsset & { type: 'prop' };
};

export const ValuePropCard = (props: ValuePropCardProps) => {
  const isMobile = useIsMobileScreen();
  const { data } = props;
  const { label, link, contentList } = data;

  return (
    <Flex alignItems="center" justifyContent="center" position="relative" width="100%" height="100%">
      <Box
        borderRadius={1}
        boxShadow={BOX_SHADOW_8}
        height="95%"
        width="100%"
        overflow="hidden"
        position="relative"
        margin="16px"
        marginBottom="auto"
        transition={animationTransition('box-shadow')}
        _groupHover={{
          boxShadow: `${BOX_SHADOW_8},${BOX_SHADOW_60_02}`
        }}
      >
        <ContentCarousel autoRotatePeriodMs={isMobile ? 3000 : undefined} content={contentList} label={label} link={link} currentResponsiveSize={isMobile ? 'mobile' : 'desktop'} />
      </Box>
    </Flex>
  );
};
