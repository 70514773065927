import { useEffect, useState } from 'react';
import { useAuth } from '@shared/components/AuthProvider';
import safeUrlToRedirect from '@shared/utils/safeUrlToRedirect';
import { useRouterHelper } from '@shared/core';
import { Mode, Provider } from '@apps/account/types';
import { useQueryParamHelper } from '@shared/core/queryString';
import { withWindow } from '@shared/utils/withWindow';
import { updateSearchParam } from '../../utils';
import { useAccountTelemetry } from '@apps/account/account.telemetry';
import { config } from '@static/js/joy';

export const ProviderMap: Readonly<{ [key: string]: Provider }> = {
  google: 'google',
  facebook: 'facebook',
  emailpassword: 'emailpassword'
};

export type ProviderSettings = Readonly<
  {
    [key in Provider]?: boolean;
  }
>;

interface SignInPageControllerState
  extends Readonly<{
    mode: Mode | undefined;
    providers: ProviderSettings;
    redirectUri: string | undefined;
  }> {}

export function useSignInPageController() {
  const routerHelper = useRouterHelper();
  const authProvider = useAuth();
  const telemetry = useAccountTelemetry();
  const queryParamHelper = useQueryParamHelper();
  const [signInPageState, setSignInPageState] = useState<SignInPageControllerState>({ mode: undefined, providers: {}, redirectUri: undefined });

  useEffect(() => {
    const mode = queryParamHelper.getValueString('mode')?.toLowerCase();
    const providers = (queryParamHelper.getValueArray('provider') || [])
      ?.map(p => ProviderMap[p])
      .filter(p => !!p)
      .reduce(
        (settings: ProviderSettings, p: Provider) => ({
          ...settings,
          [p]: true
        }),
        {}
      );
    const prevParam = queryParamHelper.getValueString('prev');
    if (mode === 'signup' || mode === 'signin' || mode === 'forgotpassword') {
      setSignInPageState({
        mode,
        providers,
        redirectUri: prevParam ? prevParam : new URL('/account-redirect', config.clientUri).href
      });
    } else {
      setSignInPageState({
        mode: 'redirect',
        providers: {},
        redirectUri: ''
      });
    }
  }, [queryParamHelper]);

  useEffect(() => {
    const mode = queryParamHelper.getValueString('mode')?.toLowerCase();
    if (mode === 'signup') {
      telemetry.signUp.enter();
    } else if (mode === 'signin') {
      telemetry.signIn.enter();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParamHelper]);

  useEffect(() => {
    withWindow(({ location }) => {
      if (signInPageState.redirectUri && authProvider.isLoggedIn) {
        if (safeUrlToRedirect(signInPageState.redirectUri)) {
          // here we're allowing full URL location set for external app routing
          // e.g. dev.withjoy.com/me
          location?.assign(signInPageState.redirectUri);
        }
        routerHelper.goToExactRoute(signInPageState.redirectUri);
      }
    });
  }, [signInPageState.redirectUri, authProvider.isLoggedIn, routerHelper]);

  const logoutClickHandler = async () => {
    const variables = {}; // no active Event
    await authProvider.provideLogout(variables);
  };
  const switchMode = (mode: Mode) => {
    withWindow(({ location }) => {
      const newSearch = updateSearchParam(location.search, 'mode', mode);
      routerHelper.goToExactRoute(location.pathname, `${newSearch}${location.hash}`);
    });
  };

  return {
    mode: signInPageState.mode,
    providers: signInPageState.providers,
    redirectUri: signInPageState.redirectUri,
    logoutClickHandler,
    isSignedIn: authProvider.isLoggedIn,
    userProfile: authProvider.currentUser.profile,
    switchMode
  };
}
