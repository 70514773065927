import { useRouteMatch } from '@shared/core';
import { NEW_DIGITAL_EDITOR_ROUTE } from '../../Card';

export const useIsEditingDigitalCard = () => {
  const match = useRouteMatch(NEW_DIGITAL_EDITOR_ROUTE);

  const isEditingDigitalCard = match !== null;

  return { isEditingDigitalCard };
};
