import React, { ReactElement, useMemo } from 'react';
import { Box, Flex, TextV2 } from '@withjoy/joykit';
import { useIsMobileOrTablet, useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { ChoiceGroup, QuantityDialogProvider } from '@apps/card/routes/CardCustomizer';
import { CardFormatControl } from '../../ProductDetailsPage.types';
import { Breadcrumbs } from '../Breadcrumbs';
import { CustomizeNowButton } from '../../CustomizeNowButton';
import { Layout } from '../Layout/Layout';
import { CardFormat } from '../../constants';
import { TitleBlock } from '../TitleBlock';
import { Skeleton } from '@shared/components/Skeleton';
import { useFeatureValue } from '@shared/core/featureFlags';
import { DIGITAL_PRICE_CONFIG } from '@apps/card/config';
import { useMinorUnitsToPriceString } from '@apps/card/hooks/useMinorUnitsToPriceString';
import { useGetDiscountedPrice } from '../../../DesignsGallery/useCardDiscountContext';
import { SalePurpleColor } from '../../../DesignsGallery/components/CardTileDiscountedPriceLabel';

interface PdpLayoutProps {
  designName: string;
  templateCategory: StationeryTemplateCategoryEnum;
  showCustomizeButtonInHeader: boolean;
  titleBlock: () => JSX.Element;
  onCustomizeNowClick: () => void;
  isCreatingDraft: boolean;
  cardFormatControl: CardFormatControl;
  subtotal?: string;
  previewPane: () => JSX.Element;
  customizationPane: () => JSX.Element;
  disableCustomizeNowButton?: boolean;
  isPremium?: boolean;
}

const CONTENT_HORIZONTAL_PADDING = { _: 6, sm2: 7 };
const Container: React.FC = ({ children }) => {
  return (
    <Flex justifyContent="center" width="100%">
      <Layout.Container display="flex" flexDirection="column" alignItems="center" marginTop={{ sm2: 8 }} height="100%" width={1280} maxWidth="100%">
        {children}
      </Layout.Container>
    </Flex>
  );
};

const Header: React.FC<{ showCustomizeButtonInHeader: boolean }> = ({ children, showCustomizeButtonInHeader }) => {
  return (
    <Box width="100%" marginBottom={{ _: 6, sm2: 0 }} marginTop={{ _: 6, sm2: 0 }} paddingX={CONTENT_HORIZONTAL_PADDING}>
      <Layout.Header alignItems="center" paddingY={{ sm2: 4 }} display="flex" justifyContent="space-between" paddingRight={showCustomizeButtonInHeader ? 60 : undefined}>
        {children}
      </Layout.Header>
    </Box>
  );
};

const Body: React.FC = ({ children }) => {
  return (
    <Box width="100%" paddingX={CONTENT_HORIZONTAL_PADDING} marginBottom={60}>
      <Layout.Body>{children}</Layout.Body>
    </Box>
  );
};

const PreviewPanel = ({ title, content }: { title: () => JSX.Element; content: () => JSX.Element }) => {
  const isMobile = useIsMobileScreen();

  return (
    <Layout.LeftPanel>
      {isMobile && title()}
      {content()}
    </Layout.LeftPanel>
  );
};

const DiscountedPriceText = ({ originalPrice, newPrice }: { originalPrice: string; newPrice: string }) => {
  const splitPrice = originalPrice.split(' ');
  return (
    <TextV2>
      <TextV2 as="span" typographyVariant="body3">
        {splitPrice[0]}{' '}
      </TextV2>
      <TextV2 as="span" textDecoration="line-through" color="mono10" typographyVariant="body3">
        {splitPrice[1]}
      </TextV2>{' '}
      <TextV2 as="span" color={SalePurpleColor} fontWeight={600 as const} typographyVariant="body3">
        {newPrice}
      </TextV2>
    </TextV2>
  );
};

const ActionItem = ({ selected, title, price }: { selected: boolean; title: string; price?: string | ReactElement }) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" flex={1} paddingX={3}>
      <TextV2 typographyVariant="body1" color="mono12" textTransform="capitalize" fontWeight={selected ? 600 : 400}>
        {title}
      </TextV2>
      {typeof price === 'string' ? (
        <TextV2 typographyVariant="body3" color="mono12" fontWeight={selected ? 600 : 400}>
          {price}
        </TextV2>
      ) : (
        price
      )}
    </Flex>
  );
};

const ActionsPanel = ({
  cardFormatControl,
  content,
  title,
  isPremium
}: {
  content: () => JSX.Element;
  title: () => JSX.Element;
  cardFormatControl?: PdpLayoutProps['cardFormatControl'];
  isPremium?: boolean;
}) => {
  const isMobile = useIsMobileScreen();

  const { value: printEnableDigitalEditor } = useFeatureValue('printEnableDigitalEditor');
  const minorUnitsToDollars = useMinorUnitsToPriceString();
  const hasPremiumDigitalOption = printEnableDigitalEditor === 'on';
  const isPaperOnly = cardFormatControl?.supportedTemplateFormats === 'paper';
  const hasBasicDigital = printEnableDigitalEditor === 'on' ? !isPremium && !isPaperOnly : !isPaperOnly;

  const availableChoices = useMemo<CardFormat[]>(() => {
    const choices = [CardFormat.paper];

    if (hasPremiumDigitalOption) {
      choices.push(CardFormat.premiumDigital);
    }

    if (hasBasicDigital) {
      choices.push(CardFormat.digital);
    }
    return choices;
  }, [hasBasicDigital, hasPremiumDigitalOption]);

  const getDiscountedPrice = useGetDiscountedPrice();
  const { discountedPrice } = getDiscountedPrice();

  return (
    <Layout.RightPanel>
      <Flex flexDirection="column" paddingRight={{ md: 8 }} marginTop={{ _: 0, sm2: 0 }}>
        {!isMobile && title()}
        {cardFormatControl && availableChoices.length > 1 && (
          <Box marginBottom={7}>
            <ChoiceGroup title="Card Format" value={cardFormatControl.cardFormat} minHeight={55} choices={availableChoices} setValue={cardFormatControl.onChange} columns={1}>
              <ActionItem
                selected={cardFormatControl.cardFormat === CardFormat.paper}
                title="Classic Paper"
                price={discountedPrice ? <DiscountedPriceText originalPrice={cardFormatControl.cardFormatPrice} newPrice={discountedPrice} /> : cardFormatControl.cardFormatPrice}
              />
              {hasPremiumDigitalOption && (
                <ActionItem
                  selected={cardFormatControl.cardFormat === CardFormat.premiumDigital}
                  title="Premium Digital"
                  price={minorUnitsToDollars(DIGITAL_PRICE_CONFIG.premiumPrice, { canShowFreeLabel: false })}
                />
              )}
              {hasBasicDigital && <ActionItem selected={cardFormatControl.cardFormat === CardFormat.digital} title="Basic Digital" price="Free" />}
            </ChoiceGroup>
          </Box>
        )}
        {content()}
      </Flex>
    </Layout.RightPanel>
  );
};

const Footer: React.FC = ({ children }) => {
  return (
    <Box
      justifyContent="center"
      borderTop="1px solid"
      borderTopColor="mono3"
      paddingX={CONTENT_HORIZONTAL_PADDING}
      paddingY={6}
      position="sticky"
      bottom={0}
      width="100%"
      backgroundColor="white"
    >
      {children}
    </Box>
  );
};

export const PdpLayout = (props: PdpLayoutProps) => {
  const {
    disableCustomizeNowButton,
    isCreatingDraft,
    previewPane,
    customizationPane,
    designName,
    templateCategory,
    titleBlock,
    onCustomizeNowClick,
    showCustomizeButtonInHeader,
    cardFormatControl,
    isPremium
  } = props;

  const isMobileOrTablet = useIsMobileOrTablet();

  return (
    <QuantityDialogProvider isInsidePDP>
      <Container>
        <Header showCustomizeButtonInHeader={showCustomizeButtonInHeader}>
          <Breadcrumbs designName={designName} templateCategory={templateCategory} />
          {showCustomizeButtonInHeader && <CustomizeNowButton disabled={disableCustomizeNowButton} onCustomizeClick={onCustomizeNowClick} isLoading={isCreatingDraft} />}
        </Header>
        <Body>
          <PreviewPanel title={titleBlock} content={previewPane} />
          <ActionsPanel cardFormatControl={cardFormatControl} title={titleBlock} content={customizationPane} isPremium={isPremium} />
        </Body>
        {isMobileOrTablet && (
          <Footer>
            <CustomizeNowButton disabled={disableCustomizeNowButton} fullWidth onCustomizeClick={onCustomizeNowClick} isLoading={isCreatingDraft} />
          </Footer>
        )}
      </Container>
    </QuantityDialogProvider>
  );
};

const PdpLayoutSkeleton = () => {
  return (
    <Container>
      <Header showCustomizeButtonInHeader={false}>
        <Breadcrumbs.Skeleton />
      </Header>
      <Body>
        <PreviewPanel title={TitleBlock.Skeleton} content={() => <Skeleton aspectRatio={'1'} />} />
        <ActionsPanel
          cardFormatControl={undefined}
          title={TitleBlock.Skeleton}
          content={() => (
            <Flex flexDirection="column" rowGap={7}>
              <ChoiceGroup.Skeleton minHeight={55} choices={2} />
              <ChoiceGroup.Skeleton choices={0} disablePriceSkeleton />
              <ChoiceGroup.Skeleton choices={4} />
              <ChoiceGroup.Skeleton choices={3} />
              <ChoiceGroup.Skeleton choices={1} columns={1} minHeight={50} />
              <ChoiceGroup.Skeleton choices={1} columns={1} minHeight={48} disablePriceSkeleton />
            </Flex>
          )}
        />
      </Body>
    </Container>
  );
};

PdpLayout.Skeleton = PdpLayoutSkeleton;
