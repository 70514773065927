import React from 'react';
import { Stack, Flex } from '@withjoy/joykit';
import { ProviderSettings } from '@apps/account/routes/SignIn/SignInPage.controller';
import { useShowThirdPartyProviders } from '@apps/account/utils';
import { ReactComponent as FacebookIcon } from '@assets/icons/facebook.svg';
import { ReactComponent as GoogleIcon } from '@assets/icons/google.svg';
import { Button3rdPartyProvider } from './SignInUp3rdPartyProviders.styles';
import { useTranslation } from '@shared/core';

interface Props
  extends Readonly<{
    mode: 'signin' | 'signup';
    providers: ProviderSettings;
    onThirdPartyClick: (type: keyof ProviderSettings) => void;
  }> {}

export const SignInUp3rdPartyProviders: React.FC<Props> = ({ mode, providers, onThirdPartyClick }) => {
  const showThirdPartyProviders = useShowThirdPartyProviders(providers);
  const { t2 } = useTranslation('account');
  const tSignIn = t2('signin');
  const tSignUp = t2('signup');
  const showOrWithEmail = Object.keys(providers).length > 1 && providers.emailpassword;
  return (
    <Stack spacing={6} margin={10}>
      {(showThirdPartyProviders && (
        <>
          {providers.facebook && (
            <Button3rdPartyProvider icon={<FacebookIcon />} fill={true} intent="secondary" {...{ type: 'button' }} onClick={() => onThirdPartyClick('facebook')}>
              {tSignIn.facebook}
            </Button3rdPartyProvider>
          )}
          {providers.google && (
            <Button3rdPartyProvider icon={<GoogleIcon />} fill={true} intent="secondary" {...{ type: 'button' }} onClick={() => onThirdPartyClick('google')}>
              {mode === 'signin' ? tSignIn.google : tSignUp.google}
            </Button3rdPartyProvider>
          )}
          {showOrWithEmail && (
            <Flex justifyContent="center">
              <span>{tSignIn.or}</span>
            </Flex>
          )}
        </>
      )) ||
        null}
    </Stack>
  );
};
