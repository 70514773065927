import React, { useMemo } from 'react';
import { NonEditableCardPagePreview } from '@apps/card/routes/CardCustomizer';
import { Diamond } from '@withjoy/joykit/icons';

import { AssetContainer, CardPreviewPaneProps, GalleryAsset, GenericPreviewPane } from '../GenericPreviewPane';

const CARD_EDITOR_PREVIEW_URL = 'https://withjoy.com/assets/public/card-editor-preview.jpg';
const SMS_DELIVERY_PREVIEW_URL = 'https://withjoy.com/assets/public/text-delivery-preview.jpg';

export const CardPreviewPane = (props: CardPreviewPaneProps) => {
  const { cardFront, isTemplatePremium, cardShape, thumbnailUrl } = props;

  const assets: Array<GalleryAsset> = useMemo(() => {
    return [
      {
        render: () => (
          <AssetContainer key="cardFront" label="Front of the Card" badge={isTemplatePremium ? <Diamond size={40} color="#DFC080" /> : undefined}>
            <NonEditableCardPagePreview enableShadow page={cardFront} shape={cardShape} />
          </AssetContainer>
        ),
        thumbnailUrl: thumbnailUrl
      },
      {
        render: () => <AssetContainer enforceAspectRatio key="cardEditor" label="Card Editor" backgroundImageUrl={CARD_EDITOR_PREVIEW_URL}></AssetContainer>,
        thumbnailUrl: CARD_EDITOR_PREVIEW_URL
      },
      {
        render: () => <AssetContainer enforceAspectRatio key="smsDelivery" label="SMS Delivery" backgroundImageUrl={SMS_DELIVERY_PREVIEW_URL}></AssetContainer>,
        thumbnailUrl: SMS_DELIVERY_PREVIEW_URL
      }
    ];
  }, [thumbnailUrl, isTemplatePremium, cardFront, cardShape]);

  return <GenericPreviewPane {...props} assets={assets} />;
};
