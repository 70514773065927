import React, { useMemo } from 'react';
import { ButtonV2 } from '@withjoy/joykit';
import { Link } from '@react-router';
import { ImageWrapper, InfoWrapper, TitleText, InfoText, SubText, CTAContainer } from '../BookingDialog.styles';
import { useRouterHelper, useTranslation } from '@shared/core';
import { useDashboardTelemetry } from '@apps/admin/routes/Dashboard/Dashboard.telemetry';
import { PlanningRoutes } from '@apps/admin/routes/Planning/Planning.routes';

export const BookingDialogIntro: React.FC<{ setStep: (step: number) => void; openInNewTab?: boolean; onRedirectClick?: () => void }> = ({
  setStep,
  openInNewTab,
  onRedirectClick
}) => {
  const { t2 } = useTranslation('bookingDialog');
  const { titleText, infoText, subText, ctaConfirm } = t2('intro');
  const routeHelpers = useRouterHelper();

  const generatedHotelBlockUrl = useMemo(() => {
    const url = routeHelpers.buildPath(PlanningRoutes.hotelblock);
    return url;
  }, [routeHelpers]);

  const { bookingDialogCTAClicked } = useDashboardTelemetry();

  return (
    <>
      <ImageWrapper
        backgroundRepeat={'no-repeat'}
        backgroundSize={'cover'}
        backgroundImage={`url('https://withjoy.com/assets/public/booking-hotel-variant3.png')`}
        backgroundPosition={['0% 0%', '0% 0%', '50% 50%']}
      />
      <InfoWrapper>
        <TitleText maxWidth={{ _: 300, xs: 416, sm: 300 }} typographyVariant={'Hed40'} paddingBottom={'1rem'}>
          {titleText}
        </TitleText>
        <InfoText>{infoText}</InfoText>
        <SubText paddingBottom={{ _: 24, sm: 40 }}>{subText}</SubText>
        <CTAContainer flexDirection="column" rowGap={16}>
          <ButtonV2
            width={['100%']}
            intent="neutral"
            variant="solid"
            as={Link}
            to={generatedHotelBlockUrl}
            target={openInNewTab ? '_blank' : undefined}
            onClick={() => {
              bookingDialogCTAClicked('');
              onRedirectClick?.();
            }}
          >
            {ctaConfirm}
          </ButtonV2>
        </CTAContainer>
      </InfoWrapper>
    </>
  );
};
