import React, { ReactNode } from 'react';
import { animationTransition } from '@shared/utils/animationTransition';
import { Box, BoxProps, Flex, TextV2 } from '@withjoy/joykit';
import { Clock, Diamond } from '@withjoy/joykit/icons';
import { BOX_SHADOW_60_02, BOX_SHADOW_8 } from '@apps/card/boxShadow';
import { Skeleton } from '@shared/components/Skeleton';
import { AspectRatioImage } from '../AspectRatioImage';

interface GalleryTileProps {
  onClick?: React.MouseEventHandler;
  rowGap?: BoxProps['rowGap'];
  children: React.ReactNode;
}

interface AssetPreview {
  assetUrl: string;
  children?: ReactNode;
}

const AssetPreviewFrame = (props: { children?: React.ReactNode }) => {
  return (
    <Flex aspectRatio="9 / 10" flexDirection="column" alignItems="center" justifyContent="center" padding={5}>
      {props.children}
    </Flex>
  );
};

export const AssetPreview = (props: AssetPreview) => {
  const { assetUrl, children } = props;
  return (
    <AssetPreviewFrame>
      <AspectRatioImage
        src={assetUrl}
        borderRadius={1}
        boxShadow={BOX_SHADOW_8}
        transition={animationTransition('box-shadow')}
        _groupHover={{
          boxShadow: `${BOX_SHADOW_8},${BOX_SHADOW_60_02}`
        }}
      >
        {children}
      </AspectRatioImage>
    </AssetPreviewFrame>
  );
};

AssetPreview.Skeleton = () => {
  return (
    <AssetPreviewFrame>
      <Skeleton boxShadow={BOX_SHADOW_8} aspectRatio="5 / 7" height="100%" />
    </AssetPreviewFrame>
  );
};

export type TagVariant = 'comingSoon' | 'printAvailable' | 'disabled' | 'printAndDigitalAvailable' | 'digitalOnly' | 'printAndPremiumDigitalAvailable';

interface TagProps {
  variant: TagVariant;
}

const TAG_VARIANT_MAP: Record<TagProps['variant'], { Icon: () => JSX.Element | null; label: string }> = {
  comingSoon: {
    Icon: () => <Clock size="md" color="positive7" />,
    label: 'Coming Soon'
  },
  printAvailable: {
    Icon: () => null,
    label: 'Paper Only'
  },
  printAndPremiumDigitalAvailable: {
    Icon: () => null,
    label: 'Paper & Digital'
  },
  disabled: {
    Icon: () => <Clock size="md" color="positive7" />,
    label: 'Disabled (Admin View Only)'
  },
  printAndDigitalAvailable: {
    Icon: () => null,
    label: 'Paper & Digital'
  },
  digitalOnly: {
    Icon: () => null,
    label: 'Digital Only'
  }
};

const Tag = (props: TagProps) => {
  const { Icon, label } = TAG_VARIANT_MAP[props.variant];
  return (
    <Flex alignItems="center" columnGap={1} paddingX={3} paddingY={1} borderRadius={40} backgroundColor="mono2">
      <Icon />
      <TextV2 as="span" typographyVariant="label2">
        {label}
      </TextV2>
    </Flex>
  );
};

interface ContentProps {
  title: string;
  label: string | JSX.Element;
  children?: React.ReactNode;
  alignment?: 'left' | 'center';
}

const ContentContainer: React.FC<{ alignment: 'left' | 'center'; header: React.ReactNode; height?: BoxProps['height'] }> = ({ alignment, header, height, children }) => {
  return (
    <Flex flexDirection="column" rowGap={{ _: 1, md: 3 }} alignItems={alignment === 'left' ? 'flex-start' : 'center'} height={height}>
      <Flex flexDirection="column" rowGap={1} alignItems={alignment === 'left' ? 'flex-start' : 'center'} textAlign={alignment}>
        {header}
      </Flex>
      {children}
    </Flex>
  );
};
const Content = ({ children, label, title, alignment = 'center' }: ContentProps) => {
  return (
    <ContentContainer
      alignment={alignment}
      header={
        <>
          <TextV2 typographyVariant={{ _: 'hed1', md: 'hed2' }}>{title}</TextV2>
          {typeof label === 'string' ? <TextV2 typographyVariant="label2">{label}</TextV2> : <div>{label}</div>}
        </>
      }
    >
      {children}
    </ContentContainer>
  );
};

Content.Skeleton = ({ children, alignment = 'center' }: { children: React.ReactNode; alignment?: 'left' | 'center' }) => {
  return (
    <ContentContainer
      height={80}
      alignment={alignment}
      header={
        <>
          <Skeleton width={120} height={18} />
          <Skeleton width={50} height={16} />
        </>
      }
    >
      {children}
    </ContentContainer>
  );
};

export const GalleryTile = (props: GalleryTileProps) => {
  const { children, onClick, rowGap } = props;

  return (
    <Box display="grid" alignItems="start" role="group" position="relative" rowGap={rowGap} onClick={onClick} cursor={onClick ? 'pointer' : undefined}>
      {children}
    </Box>
  );
};

const PremiumBadge = () => (
  <Box
    typographyVariant="label1"
    fontWeight={600}
    position="absolute"
    borderRadius="50%"
    display="flex"
    alignItems="center"
    backdropFilter="blur(2px)"
    right={3}
    bottom={4}
    backgroundColor="rgba(0, 0, 0, 0.40)"
    color="white"
    userSelect="none"
    zIndex={1}
  >
    <Diamond color="#DFC080" size={24} />
  </Box>
);

GalleryTile.Skeleton = () => {
  return (
    <GalleryTile>
      <AssetPreview.Skeleton />
      <Content.Skeleton>
        <Skeleton width={70} height={18} />
      </Content.Skeleton>
    </GalleryTile>
  );
};

GalleryTile.AssetPreview = AssetPreview;
GalleryTile.AssetPreviewFrame = AssetPreviewFrame;
GalleryTile.Content = Content;
GalleryTile.Tag = Tag;
GalleryTile.PremiumBadge = PremiumBadge;
