import React, { useMemo } from 'react';
import { NonEditableCardPagePreview } from '@apps/card/routes/CardCustomizer';
import { Diamond } from '@withjoy/joykit/icons';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { AssetContainer, CardPreviewPaneProps, GalleryAsset, GenericPreviewPane } from '../GenericPreviewPane';

const BACK_VARIETY_PREVIEW_URL = 'https://withjoy.com/media/print/back-variety-preview-3.jpg';
const BACK_VARIETY_THANKYOU_PREVIEW_URL = 'https://withjoy.com/media/print/back-variety-preview-thankyoucard.jpg';
const RETURN_ADDRESSING_PREVIEW_URL = 'https://withjoy.com/media/print/envelope-address-preview-with-recipient.jpg';
const ENVELOPE_VARIETY_PREVIEW_URL = 'https://withjoy.com/media/print/envelope-variety-preview-1.jpg';

const getCardBackPreviewImage = (category: StationeryTemplateCategoryEnum) => {
  switch (category) {
    case StationeryTemplateCategoryEnum.thankYou: {
      return BACK_VARIETY_THANKYOU_PREVIEW_URL;
    }
    case StationeryTemplateCategoryEnum.holiday: {
      return 'https://withjoy.com/media/print/back-variety-preview-holiday.jpg';
    }
    default: {
      return BACK_VARIETY_PREVIEW_URL;
    }
  }
};

const getEnvelopePreviewImage = (category: StationeryTemplateCategoryEnum) => {
  switch (category) {
    case StationeryTemplateCategoryEnum.holiday: {
      return 'https://withjoy.com/media/print/envelope-variety-preview-holiday.jpg';
    }
    default: {
      return ENVELOPE_VARIETY_PREVIEW_URL;
    }
  }
};

const getEnvelopeAddressPreviewImage = (category: StationeryTemplateCategoryEnum) => {
  switch (category) {
    default: {
      return RETURN_ADDRESSING_PREVIEW_URL;
    }
  }
};

export const CardPreviewPane = (props: CardPreviewPaneProps) => {
  const { cardFront, isTemplatePremium, cardShape, thumbnailUrl, templateCategory } = props;

  const assets: Array<GalleryAsset> = useMemo(() => {
    return [
      {
        render: () => (
          <AssetContainer key="cardFront" label="Front of the Card" badge={isTemplatePremium ? <Diamond size={40} color="#DFC080" /> : undefined}>
            <NonEditableCardPagePreview enableShadow page={cardFront} shape={cardShape} />
          </AssetContainer>
        ),
        thumbnailUrl: thumbnailUrl
      },
      {
        render: () => <AssetContainer enforceAspectRatio key="cardBack" label="Back of the Card" backgroundImageUrl={getCardBackPreviewImage(templateCategory)}></AssetContainer>,
        thumbnailUrl: getCardBackPreviewImage(templateCategory)
      },
      {
        render: () => (
          <AssetContainer
            enforceAspectRatio
            key="returnAddressing"
            label="Free Recipient & Return Addressing"
            backgroundImageUrl={getEnvelopeAddressPreviewImage(templateCategory)}
          ></AssetContainer>
        ),
        thumbnailUrl: RETURN_ADDRESSING_PREVIEW_URL
      },
      {
        render: () => <AssetContainer enforceAspectRatio key="envelope" label="Envelope" backgroundImageUrl={getEnvelopePreviewImage(templateCategory)}></AssetContainer>,
        thumbnailUrl: ENVELOPE_VARIETY_PREVIEW_URL
      }
    ];
  }, [thumbnailUrl, templateCategory, isTemplatePremium, cardFront, cardShape]);

  return <GenericPreviewPane {...props} assets={assets} />;
};
