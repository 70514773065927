import { RefObject, useEffect, useRef } from 'react';
import { Updater } from 'use-immer';
import { SurfaceState } from './useSurface';

interface SelectionStrategy {
  surfacePointerDown?: (update: Updater<SurfaceState>) => void;
  isEditing(editingLayerIndex: number | undefined, layerIndex: number): boolean;
  useSelectAllText(isEditing: boolean): RefObject<HTMLDivElement>;
}

const ClickSelectionStrategy: SelectionStrategy = {
  surfacePointerDown: update => {
    update(draft => {
      draft.activeLayerIndex = undefined;
    });
  },
  isEditing(editingLayerIndex: number | undefined, layerIndex: number) {
    return editingLayerIndex === layerIndex;
  },
  useSelectAllText(isEditing: boolean) {
    const contentEditableRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (!isEditing) return;
      setTimeout(() => {
        if (!contentEditableRef.current) return;
        const range = document.createRange();
        range.selectNodeContents(contentEditableRef.current);
        const sel = window.getSelection();
        sel?.removeAllRanges();
        sel?.addRange(range);
      });
    }, [isEditing]);

    return contentEditableRef;
  }
};

export const CurrentSelectionStrategy = ClickSelectionStrategy;
