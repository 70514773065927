import { StationeryTemplate } from './DesignsGallery.types';
import { FORMAT_DIGITAL_COMPOSITE_CODE, FORMAT_PAPER_DIGITAL_COMPOSITE_CODE } from './components/Filters/Filters.constants';
import { useActivePrintFiltersVar } from './utils/activePrintFiltersCache';
import { useCallback } from 'react';

/**
 * Will need to revisit this once we move filtering capabilities to the BE.
 */
export const useApplyFiltersToTemplates = () => {
  const activePrintFilters = useActivePrintFiltersVar();

  // Temporary while we wait for count/filters to be implemented in server
  const deriveFilterCountFromTemplates = useCallback((templates: StationeryTemplate[]) => {
    return templates.reduce((acc, template) => {
      let compositeCode: string | undefined;
      switch (template.format) {
        case 'digital': {
          compositeCode = FORMAT_DIGITAL_COMPOSITE_CODE;
          break;
        }
        case 'paperAndDigital': {
          compositeCode = FORMAT_PAPER_DIGITAL_COMPOSITE_CODE;
          break;
        }
      }

      if (compositeCode) {
        acc[compositeCode] = acc[compositeCode] || 0;
        acc[compositeCode]++;
      }

      return acc;
    }, {} as Record<string, number>);
  }, []);

  const applyFiltersToTemplates = useCallback(
    (templates: StationeryTemplate[]) => {
      if (Object.keys(activePrintFilters).length === 0) {
        return templates;
      }

      return templates.filter(template => {
        if (activePrintFilters[FORMAT_DIGITAL_COMPOSITE_CODE] && template.format === 'digital') {
          return true;
        }

        if (activePrintFilters[FORMAT_PAPER_DIGITAL_COMPOSITE_CODE] && template.format === 'paperAndDigital') {
          return true;
        }

        return false;
      });
    },
    [activePrintFilters]
  );

  return { applyFiltersToTemplates, deriveFilterCountFromTemplates };
};
