import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { activePrintFiltersVar } from './utils/activePrintFiltersCache';
import { convertObjectToQueryParams } from './utils/deriveActiveFiltersFromUrl';
import qs from 'query-string';
import { useLocation } from '@shared/core';

type FacetGroupMap = {
  [facet: string]: string[];
};

export const useFormattedPrintFiltersFacetHref = () => {
  const activePrintFilters = useReactiveVar(activePrintFiltersVar);

  const location = useLocation();

  const baseUrl = `${location.pathname}${location.search}${location.hash}`;

  const getQueryStringFromParams = useCallback(
    (facetGroupMap: FacetGroupMap) => {
      const filterAsQueryParam = convertObjectToQueryParams(facetGroupMap);

      return qs.stringifyUrl(
        {
          url: baseUrl,
          query: {
            pf: filterAsQueryParam
          }
        },
        { skipNull: true, skipEmptyString: true }
      );
    },
    [baseUrl]
  );

  const deriveHref = useCallback(
    (compositeCode: string, checked: boolean) => {
      const clonedActivePrintFilters = { ...activePrintFilters, [compositeCode]: checked };

      const facetGroupMap = Object.keys(clonedActivePrintFilters)
        .sort()
        .reduce((acc, filterCompositeCode) => {
          const isActive = clonedActivePrintFilters[filterCompositeCode];

          if (isActive) {
            const [groupCode, valueCode] = filterCompositeCode.split(':');
            acc[groupCode] = acc[groupCode] || [];
            acc[groupCode].push(valueCode);
          }
          return acc;
        }, {} as FacetGroupMap);

      return getQueryStringFromParams(facetGroupMap);
    },
    [activePrintFilters, getQueryStringFromParams]
  );

  return { deriveHref };
};
