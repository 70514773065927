import React from 'react';
import { TileTypes } from '../../usePropValues';
import { CardTileConcierge } from './components/CardTileConcierge';
import { CardLink } from './components/CardLink';
import { CardTile } from './components/CardTile';
import { ValuePropCard } from './components/ValueProps/ValuePropCard';

export type ConciergeSelectedEvent = {
  id: string;
};

export type CardEventType = ConciergeSelectedEvent & { type: 'conciergeselectedevent' };

type GalleryItemProps = {
  data: TileTypes;
  eventDesignThemeId?: string;
  onCardSelect: (event: CardEventType) => void;
};

export const GalleryItem = (props: GalleryItemProps) => {
  const { data, eventDesignThemeId, onCardSelect } = props;
  switch (data.type) {
    case 'prop':
      return <ValuePropCard data={data} />;
      break;
    case 'cardlink':
      return <CardLink data={data} />;
      break;
    case 'template':
      return <CardTile data={data} eventDesignThemeId={eventDesignThemeId} />;
      break;
    case 'concierge':
      return <CardTileConcierge data={data} onSelect={onCardSelect} />;
      break;
  }
};
